import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";

import { BaseViewModel } from "@/modelviews/BaseViewModel";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { type IPrefCityRepository } from "@/datasource/repositories/prefcity/prefcityRepository";
import { IZipToPrefCity } from "@/models/Prefcity";
import { ICity, IPref } from "@/models/Lab";
import { setCityList, setPrefList } from "@/store/prefcity";
import { setFormFieldSubmit, setFromFiledCustomSubmit, setFormFieldLaboSubmit } from "@/store/register";

@injectable()
export class PrefCityViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.PrefCityRepository) private prefCityRepository: IPrefCityRepository
  ) {
    super();
  }

  public async zipCusToPrefCity(params: any, attr : any ): Promise<IDataResponse<IZipToPrefCity>> {

    const result = await this.prefCityRepository.zipToPrefCity(params);

    if(result.data && result.success) {
      const pref = result.data.Pref;
      const city = result.data.City;
      const lab_address = result.data.ward;

      this.hooks.dispatch( setFromFiledCustomSubmit({value: pref.id.toString(), name: attr.pref }) );
      this.hooks.dispatch( setFromFiledCustomSubmit({value: city.id.toString(), name: attr.city }) );

      //update for register form


      if( lab_address )
      this.hooks.dispatch( setFromFiledCustomSubmit({value: lab_address, name: attr.address }) );
      await this.getCityByPref({pref_id: pref.id});
    }
    return result;
  }

  public async zipToPrefCity(params: any, typeField: 'laboUpdate' | any = ''): Promise<IDataResponse<IZipToPrefCity>> {

    const result = await this.prefCityRepository.zipToPrefCity(params);

      
    const pref = result?.data?.Pref || { id: ""};
    const city = result?.data?.City || { id: ""};
    const lab_address = result?.data?.ward || "";

      //update for register form
    switch(typeField)
    {
      case 'laboUpdate':
        const labelName: any = { city_id: '市区町村（郡）', lab_address: '町名・番地' }
        const require = ['lab_name', 'lab_corp_kbn', 'lab_name_f', 'lab_address', 'pref_id', 'city_id', 'lab_id', 'lab_zip', 'lab_phone'];
        this.hooks.dispatch(setFormFieldLaboSubmit({value: pref.id.toString(), name: 'pref_id', req:require, labelName }));
        this.hooks.dispatch(setFormFieldLaboSubmit({value: city.id.toString(), name: 'city_id', req:require, labelName }));
        this.hooks.dispatch(setFormFieldLaboSubmit({value: lab_address, name: 'lab_address', req:require, labelName }));
        break;
      default:
        this.hooks.dispatch(setFormFieldSubmit({value: pref.id.toString(), name: 'pref_id'}));
        this.hooks.dispatch(setFormFieldSubmit({value: city.id.toString(), name: 'city_id'}));
        this.hooks.dispatch(setFormFieldSubmit({value: lab_address, name: 'lab_address'}));
        break;
    }

    if(result.data && result.success) 
      await this.getCityByPref({pref_id: pref.id});
    
    return result;
  }
  public async getListPref(): Promise<IDataResponse<IPref[]>> {
    const result = await this.prefCityRepository.getListPref();
    if(result.data && result.success) {
      this.hooks.dispatch(setPrefList(result.data))
    }else{
      this.hooks.dispatch(setPrefList([]))
    }
    return result;
  }

  public async getCityByPref(params: any): Promise<IDataResponse<ICity[]>> {
    const result = await this.prefCityRepository.getCityByPref(params);

    if(result.data && result.success) {
      this.hooks.dispatch(setCityList(result.data))
    }else{
      this.hooks.dispatch(setCityList([]))
    }
    return result;
  }
}
