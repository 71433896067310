import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IAuthToken, IUserCredentials } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import { setLoading, setMsg } from "@/store/global";
import { msg } from "@msg";
import dayjs from "dayjs";
import { setFromFiledCustomSubmit } from "@/store/register";
import { BaseViewModel } from "../BaseViewModel";
import { setLoadData  } from "@/store/setting";
import { message } from "@/constants/message";
import { setModal } from "@/store/global";
import { Material } from "@/helpers/material";

@injectable()
export class ImplantViewModel extends BaseViewModel {

  constructor(
    @inject(TYPES.ImplantRepository) private implantRepository: any,
    @inject(TYPES.ImplantRepository) public limit: number,
  ) {
    super();
    this.limit = 50;
  }


  public async fetchManuByProdcut( id : any, params: any) : Promise<any>
  {
    return await this.implantRepository.fetchManuByProdcut(id, params)
  }

  public async renderData( params:  { [key: string] : any }, callback: Function )
  {

    params =  Object.keys(params)
                      .filter( (key) => params[key] )
                      .reduce( (cur, key) => Object.assign(cur, { [key]: params[key] }) , {} );

    let res: any = await this.withLoading( () => this.implantRepository.fetchAll({ params }) );

    if ( res.success)
    {
      res.data.category_id = params.category_id || '';
      return callback( res )
    }
  }
  /*
    includes: String,
    material_category_id: number
    limit: number | -1
    page: number
  */
  public fetchManuFactures = async(params: any) : Promise<any> => 
  {
    return await this.implantRepository.fetchAll(params);
  }

  public async  metal( ) : Promise<any>
  {
      const { metalType } = this.hooks.getState().setting;

      if( metalType.length )
        return metalType;

      const res: any = await this.withLoading( () => this.implantRepository.fetchMetal({  }) );

      if ( res.success)
      {
        this.hooks.dispatch( setLoadData({ ...res , type: 'metalType' }) );
        return res.data
      }

  }

  public async  categories( ) : Promise<any>
  {

      const res: any = await this.withLoading( () => this.implantRepository.fetchCategories({  }) );

      if ( res.success)
      {
        this.hooks.dispatch( setLoadData({ ...res , type: 'categories' }) );
        return res.data
      }

  }

  public async  getclinics( params : { keyword: string, page: number, limit: number } ) : Promise<any>
  {
      const res: any = await this.withLoading( () => this.implantRepository.getclinics({ params }) );
    if ( res.success)
      return this.hooks.dispatch( setLoadData({ ...res.data, keyword: params.keyword , type: 'clinics' }) );
  }

  public async  dispatchField() : Promise<any>
  {
    const { id } = this.hooks.router.query;

    let field: any = ['name', 'code', 'material_category_id'];
     await this.fetch( true, 'id' );
    const { manufacture } : any = this.hooks.getState().setting;
    

    const includes = ['name', 'material_category_id']

    Object.keys(manufacture)
          .filter( (key : string) => includes.includes(key as string ) )
          .forEach( key => {
              if( manufacture[key] as string )
              this.hooks.dispatch( setFromFiledCustomSubmit( { value: manufacture[key].toString(), name: key.toString() } ) );
          });

  }


  public async  fetch( is_reload: boolean = false , primary_key: string = '' ) : Promise<any>
  {

      const category: any  = new Material( this.hooks ).getCategory();

      let  manufacture_id: any;

      switch( primary_key )
      {
        case 'id':
            manufacture_id  = this.hooks.router.query.id;
            break;
        default:
            manufacture_id  = this.hooks.router.query.manufacture_id;
            break;

      }

      let item: any = await new Promise<void>((resolve, reject) => {
                    this.renderData( { material_category_id: category },
                                    (res: any) => {
                                                      this.hooks.dispatch( setLoadData({ ...res, type: 'manufactures' }) )
                                                      return resolve(res.data);
                                                  } )
                                    })
      

      if( !manufacture_id )
        return false;

      const  data = item.find( (val : any) => val.id == manufacture_id )

      if( ! data?.id )
        return this.hooks.router.back()

      this.hooks.dispatch( setLoadData({ data, type: 'manufacture' }) )

  }

  public async  update( param: any ) : Promise<any>
  {


    const { id } = this.hooks.router.query;

    const res: any = await  this.withLoading( () => this.implantRepository.update( id, param ) );

    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.success, 1);
    await this.fetch( true );

    this.hooks.router.push(`/setting/material/implant/variations?manufacture_id=${res.data.id}&category=${param.material_category_id}`)
  }

  public async  create( param: any  ) : Promise<any>
  {
    ///await this.checkProfile();

    const res: any = await this.withLoading( () => this.implantRepository.create( param ) );

    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.success, 1);
    await this.fetch( true );
    this.hooks.router.push(`/setting/material/implant/variations?manufacture_id=${res.data.id}&category=${param.material_category_id}`)

  }

  public async  delete( ) : Promise<any>
  {
    const { material_category_id } = this.hooks.getState().register.fromFiledCustom;

    const { id } = this.hooks.router.query;
    const res: any = await this.withLoading( () => this.implantRepository.delete( id ) );

    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.success, 1);
    await this.fetch( true );
    this.hooks.dispatch( setModal({ isCloseAll: true }) );
    this.hooks.router.push(`/setting/material?category=${material_category_id}`)

  }
  //sort
  public async  sort( ids: any  ) : Promise<any>
  {
    //await this.checkProfile();
    const { labo } = this.hooks.getState().setting;

    const res: any = await this.withLoading( () => this.implantRepository.sortOrder(ids) );

    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    const { items , current_page, last_page } = this.hooks.getState().setting.branch;
    //await this.renderData( );
    this.setMsg(res.message || message.success, 1);
    return await this.fetch( true );
  }

}
