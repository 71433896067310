import React, {useContext} from "react";
import { SlotContext } from "./SlotProvider";

interface Props {
  slot?: string;
  children: React.ReactElement;
}
interface SlotProps {
  slot?: string;
}
export function useRefactorSlot<T>(props: T, defaultSlot?: string): T {

  let slot: string = ((props as SlotProps).slot || defaultSlot) || '';
  let { [slot]: slotProps = {} } = (useContext(SlotContext) || {}) as any;
  return Object.assign(slotProps, props);
}

export const SlotWrapper = (props: Props) => {
  props = useRefactorSlot(props, "text");

  let { children, ...otherProps } = props;  
  return React.cloneElement(children, {
    ...otherProps
  });
};
