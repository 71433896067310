import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthToken, IUserCredentials } from '@models/auth';
import { AuthRepository } from '@/datasource/repositories/auth';

export interface IAuthState {
  error: string | null;
  isAuthenticated: boolean;
}
const initialState: IAuthState = {  
  error: null,
  isAuthenticated: false,
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<any>) {
      state.isAuthenticated = true;
      state.error = null;
    },
    loginFail(state, action: PayloadAction<any>) {
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.error = null;
    },
  },
});

const authReducer = authSlice.reducer;
const { loginSuccess, loginFail, logoutSuccess } = authSlice.actions;
export { authReducer, loginSuccess, loginFail, logoutSuccess };
