import type { ISettingLabRepository } from "@repositories/setting/labo/setting";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { msg } from "@msg";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { settingLabSliceAction } from "@/store/setting/lab";
import { AssetsContext } from "@/context/theme";
import React from "react";

@injectable()
export class SettingLabViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.SettingLabRepository)
    private settingLabRepo: ISettingLabRepository,

    @inject(TYPES.DepartmentRepository)
    private settingDepartmentRepo: any,

    @inject(TYPES.SettingEmployeeRepository)
    private settingEmployeeRepo: any,

    @inject(TYPES.SettingEmployeeRepository) 
      private permission: any

  ) {
    super();

    const res = React.useContext(AssetsContext) as any;
    this.permission = res?.screenPermission   
    
  }

  async getSetting(params: any): Promise<any> {
    let res =  await this.settingLabRepo.getSetting(params) 
    // this.withLoading(() =>   );

    if (res.success && res.data) {
      this.hooks.dispatch(settingLabSliceAction.fetchSetting(res.data));
      return res;
    } else {
      this.setMsg(res.message, -1);
    }
    return;
  }

  async getDepartmentList(): Promise<IDataResponse<any>> {
    let res = await this.settingDepartmentRepo.getDepart();
    if (res.success && res.data) {
      
    } else {
      this.setMsg(res?.message, -1);
    }
    return res;
  }

  async getBranches(params: any): Promise<IDataResponse<any>> {
    
    if(  ['branch', 'department', 'only_me' ].includes( this.permission?.option as string ) )
      params = { ...params, screen: this.permission?.permission }
    

    let res = await this.settingLabRepo.getBranches(params);
    if (res.success && res.data) {

      // this.hooks.dispatch(settingLabSliceAction.fetchDepartments(res.data));
    } else {
      this.setMsg(res?.message, -1);
    }
    return res;
  } 

  async getEmployees(params: any): Promise<IDataResponse<any>> {
    let res = await this.settingEmployeeRepo.getAllEmployee(params);
    if (res.success && res.data) {
      this.hooks.dispatch(settingLabSliceAction.fetchEmployees(res.data));
    } else {
      this.setMsg(res?.message, -1);
    }
    return res;
  }

  async updateSetting(data: any): Promise<any> {
    let res = await this.withLoading(() =>
      this.settingLabRepo.updateSetting(data)
    );

    if (res.success) {
      this.setMsg(msg.updateSucceed, 1);
      return res;
    } else {
      this.setMsg(res.message, -1);
      return res;
    }
  }

  async updateSettingEmployees(data: any): Promise<any> {
    let res = await this.withLoading(() =>
      this.settingLabRepo.updateSettingEmployees(data)
    );

    if (res.success) {
      this.setMsg(msg.updateSucceed, 1);
      return res;
    } else {
      this.setMsg(res.message, -1);
    }
    return;
  }

  async applyAllSettingEmployees(data: any) : Promise<any> {
    let res = await this.withLoading(() =>
      this.settingLabRepo.applyAllSettingEmployees(data)
    );

    if (res.success) {
      this.setMsg(msg.updateSucceed, 1);
      return res;
    } else {
      this.setMsg(res.message, -1);
    }
    return;
  }
}
