import React from 'react';
import Image from 'next/image';
import { HookContext, TContextHook } from "@/context/hook";
import { setModal } from '@/store/global';

export interface IProps {  
  modalTitle: string;  
  isDefaultShowOnRoute?: string;
  isDefaultShow?: boolean;
  [key: string]: any;
}
export const Modal = ({  
  modalTitle,
  modalId,
  modalType,
  modalSubType,
  modalProps,
  isDefaultShow,
  isDefaultShowOnRoute,
  ...rest
}: IProps) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const modalList = hooks.useSelector((state: any) => state.global.modalList);
  const isShow = modalList.find((item: any) => item.modalId === modalId)?.isShow;  
  const router = hooks.router.pathname;  
  
  hooks.useEffect(() => {
    if(isDefaultShow && isDefaultShowOnRoute === router){
      hooks.dispatch(setModal({
        modalId,
        isCloseAll: true,
        isShow: true
      }))
    }
  }, []);
  return (
    isShow ? 
    <>
    <div className={`${isShow && 'modal-open'}`} id={modalType}>
      <div className={`modal fade ${isShow ? 'show' : ''} ${modalSubType ? modalSubType : ''}`} id={modalId}>
        <div className={'modal-dialog modal-dialog-centered'}>
          <div className="modal-content">
            {rest.children}
          </div>
        </div>
      </div>
    </div>
    </>
    : null
  )
}