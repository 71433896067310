export const baseUtils = {
  changePassLengthToSpace: (length: number) => {
    if(length){
      let str = '';
      for (let i = 0; i < length; i++) {
        str += '*';
      }
      return str;
    }
    return '';
  },    
  formatDate(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    return formattedDate;
  }
  
  
}