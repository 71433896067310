import type { IOrderRepository } from "@repositories/order";
import type { ISettingProcessRepository } from "@repositories/setting/process";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { msg } from "@msg";
import { setWorkingTimeOrderLog } from "@/store/global";
import { message } from "@/constants/message";

@injectable()
export class OrderViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.OrderRepository)
    private orderRepo: IOrderRepository,

    @inject(TYPES.SettingProcessRepository)
    private settingProcessRepo: ISettingProcessRepository
  ) {
    super();
  }

  async getEvidences (params: any): Promise<any> {
    return await this.orderRepo.getEvidences(params);
  }

  async getServerTime (): Promise<any> {
    return await this.orderRepo.getServerTime() ;
  }
  // ** add
  async updateRemakes(params: any): Promise<any> {
    return await this.withLoading(() => this.orderRepo.updateRemakes(params) );
  }

  async removeHold(id: any): Promise<any> {
    return await this.withLoading(() => this.orderRepo.removeHold(id) );
  }

  async fetchRemakes(data: any): Promise<any> {
    const res: any=  await this.withLoading(() => this.orderRepo.fetchRemakes(data) );

    if( res?.message )
      this.setMsg(res.message || message.error, -1);

    return res
  }
  
  async setCurrentOwner(data: any): Promise<any> {
    return this.orderRepo.setCurrentOwner(data)
  }

  async getEditable(id: any): Promise<any> {
    return this.orderRepo.getEditable(id)
  }

  async createTasks(data: any): Promise<any> {
    let res = await this.withLoading(() =>
      this.settingProcessRepo.createTasks(data)
    );

    if (res.success) {
      this.setMsg(msg.updateSucceed, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async getOrderProcesses(orderIds: Array<number>): Promise<any> {
    let res = await this.orderRepo.getOrders({
      order_ids: orderIds,
      include_product: 1
    });
    return res;
  }

  async getOrderForChilds(params: any): Promise<any> {
    let res = await this.orderRepo.getOrderForChilds(params);
    return res;
  }

  async saveOrderImages(data: any): Promise<any> {
    return await this.withLoading(() => this.orderRepo.saveOrderImages(data));
  }

  // data: Form Data
  async saveOrderImagesV2(data: any): Promise<any> {
    return await this.withLoading(() => this.orderRepo.saveOrderImagesV2(data));
  }

  async removeImages(id: number, data: any): Promise<any> {
    return await this.withLoading(() => this.orderRepo.removeImages(id, data));
  }

  async cloneOrder(data: any): Promise<any> {
    return await this.withLoading(() => this.orderRepo.cloneOrder(data));
  }

  async bulkOrders(data: any): Promise<any> {
    let res = await this.withLoading(() => this.orderRepo.bulkOrders(data));
    if (res.success) {
      // handle log order working
      res.data.forEach((order: any)  => {
        let orderId = order.id
        this.hooks.dispatch(setWorkingTimeOrderLog({
          orderId: orderId,
          startTime: order.server_time,
          type: "order",
        }))
      })

      this.setMsg(msg.updateSucceed, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async saveOrders(data: any): Promise<any> {
    let res = await this.withLoading(() => this.orderRepo.saveOrders(data));
    if (res.success) {
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async fetchOrderByCode(code: string, params: any = null): Promise<any> {
    let res = await this.withLoading( () => this.orderRepo.fetchOrderByCode(code, params) );

    if( !res?.data )
      return res;

    if(res.success)
    {
      // handle log order working
      let orderId = res.data.id
      this.hooks.dispatch(setWorkingTimeOrderLog({
        orderId: orderId,
        startTime: res.data.server_time,
        type: "order",
      }))
    }
    return res;
  }

  async removeOwnOrder(params: any): Promise<any> {
    return await this.orderRepo.removeOwnOrder(params);
  }

  async fetchOrders(params: any): Promise<any> {
    let res = await this.orderRepo.fetchOrders(params);
    return res;
  }

  async fetchImages(orderId: any, params: any): Promise<any> {
    return await this.orderRepo.fetchImages(orderId, params);
  }

  async fetchOrder(id: number, params: any): Promise<any> {
    let res = await this.orderRepo.fetchOrder(id, params);
    if(res.success)
    {
      let orderId = res.data.id
      this.hooks.dispatch(setWorkingTimeOrderLog({
        orderId: orderId,
        startTime: res.data.server_time,
        type: "order",
      }))
    }
    return res;
  }

  async fetchOrderParents(orderId: number, params: any): Promise<any> {
    let res = await this.orderRepo.fetchOrderParents(orderId, params);
    return res;
  }

  async deleteOrderParent(orderId: any, data: any): Promise<any> {
    let res = await this.orderRepo.deleteOrderParent(orderId, data);
    return res;
  }

  async confirmOrder(id: number, data: any): Promise<any> {
    let res = await this.withLoading(() =>
      this.orderRepo.confirmOrder(id, data)
    );
    return res;
  }

  async updateOrder(id: number, data: any): Promise<any> {
    let res = await this.withLoading(() =>
      this.orderRepo.updateOrder(id, data)
    );

    if (res.success) {
      this.setMsg(msg.updateSucceed, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async addOrderParent(id: number, data: any): Promise<any> {
    let res = await this.withLoading(() =>
      this.orderRepo.addOrderParent(id, data)
    );

    if (res.success) {
      this.setMsg(msg.updateSucceed, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async deleteOrder(id: number): Promise<any> {
    let res = await this.withLoading(() => this.orderRepo.deleteOrder(id));

    if (res.success && res.data) {
      this.setMsg(msg.deleteSucceed, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }
}


