import * as React from "react";
import { ComponentProvider } from "./ComponentProvider";
import { SlotWrapper } from "../base/SlotWrapper";
import { Header } from "../../components/Header";
import { getServerSidePropsHOC } from "@/middleware/auth";


export const HomeLayout = (props: any) => {
  return (
    <ComponentProvider>
      {
        !props.hideHeader &&
        <SlotWrapper slot="header">
          <Header {...props} />
        </SlotWrapper>
      }
      <SlotWrapper slot="content" {...props} />
    </ComponentProvider>
  );
};
export const getServerSideProps = async (context: any) => {
  return getServerSidePropsHOC(context);
};
