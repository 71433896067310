import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import QRCode from "react-qr-code";
import BarCode from "react-barcode";
import { convertTzTo, getDayJP } from "@/helpers";


interface Print {
	printType: "barcode" | "qrcode",
	orders:  Array<any>
}

export const PrintHeaderComponent = React.forwardRef((props: Print, ref: any) => {
	const hooks = React.useContext(HookContext) as TContextHook;
  const printType = props.printType
	const orders  =  props.orders;

  return (
    <main id="main" ref={ref}>
				{orders.length > 0 && orders.map((order: any, index:  number) => (
					<section className="main-print" key={index}>
						{printType === "barcode" &&
						<section className="printpage printpage_barcode">
								<div className="block_head">
								<div className="block_head-barcode">
								{ order?.clinicCode && /^[0-9A-Z- ]+$/.test(order.clinicCode) 
									&& <BarCode format="CODE39" value={order.clinicCode}  width={1} height={30} fontSize={10} /> }
								</div>
								</div>
								<div className="block_tit">
								<h2>{order.clinic.clinic_name}</h2>
								</div>
								<div className="dflex dflex_center">
										<BarCode format="CODE39" value={order.orderCode}  width={1} height={35} fontSize={12} />
								</div>
						</section>
						}

						{printType === "qrcode" && (
							<section className="printpage printpage_qrcode">
									<div className="block_QRcode">
										<dl className="dflex">
											<dt>
											{
												order?.clinicCode && /^[0-9A-Z- ]+$/.test(order.clinicCode) &&
												<QRCode
														size={70}
														style={{
															height: "auto",
															maxWidth: "100%",
															width: "100%",
														}}
														value={order.clinicCode}
														fgColor="#000"
														viewBox={`0 0 256 256`}
													/>
											}
											</dt>
											<dd><span>医院コード</span><span className="numBar">{order?.clinicCode}</span></dd>
										</dl>
										<dl className="dflex">
											<dt>
											<QRCode
                          size={70}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={order.orderCode}
                          fgColor="#000"
                          viewBox={`0 0 256 256`}
                        />
											</dt>
											<dd><span>指示書番号</span><span className="numBar">{order.orderCode}</span></dd>
										</dl>
									</div>
									<div className="block_tit">
										<h2>{order.clinic.clinic_name}</h2>
									</div>
							</section>
						)}
					</section>
				))}

    </main>
  );
});
