import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPermissionState {
  permissionList: any[];
  permission : any;
}
const initialState: IPermissionState = {
    permissionList: [],
    permission: {},
};
export const settingPermissionSlice = createSlice({
  name: "settingPermission",
  initialState,
  reducers: {    
    setPermissionList(
      state,
      action: PayloadAction<any[]>
    ) {
      state.permissionList = action.payload;
    },
  },
});

const settingPermissionReducer = settingPermissionSlice.reducer;
const {
  setPermissionList
} = settingPermissionSlice.actions;
export {
  settingPermissionReducer,
  setPermissionList
};
