
import axiosService from "@services/axiosService";
import { IUserCredentials, IAuthToken, Profile } from "@models/auth";
import { IAuthService } from "@services/interface/IAuthService";
import { IDataResponse } from "@services/interface/IDataResponse";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { injectable } from "inversify";


@injectable()
export class LaboService {

  private prefix: string;

  constructor() {
    this.prefix = '/labs';
  }

  async updateLabs(params: any, id: number ): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}`, params);
    return response;
  }

  async getlabo( laboid : number ) {
    const response = await axiosService.get(`${this.prefix}/${laboid}`);
    return response;
  }

}