import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";


@injectable()
export class BranchRepository {
  constructor(
    @inject(TYPES.BranchService) private branchService: any,
  ) {}

  async getBranch( param: any ): Promise<IDataResponse<any>> {
    let res = await this.branchService.getBranch(param);
    return res;
  }

  async getDepartmentEmployee( params: any ): Promise<IDataResponse<any>> {
    let res = await this.branchService.getDepartmentEmployee(params);
    return res;
  }

  async createBranch(params: any,  labid: number ): Promise<IDataResponse<any>> {
    let res = await this.branchService.createBranch(params, labid);
    return res;
  }

  async updateBranch(params: any, laboid: number, braId: number  ): Promise<IDataResponse<any>> {
    let res = await this.branchService.updateBranch(params, laboid, braId);
    return res;
  }

  async deleteBranch(laboid: number, braId: number): Promise<IDataResponse<any>> {
    let res = await this.branchService.deleteBranch(laboid, braId);
    return res;
  }

  async sort( ids: Object ): Promise<IDataResponse<any>> {
    let res = await this.branchService.sort( ids );
    return res;
  }

}
