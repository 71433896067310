import React, { useState, useEffect } from 'react';
import moment from "moment";
import { HookContext, TContextHook } from '@/context/hook';
import { taskSliceAction } from "@/store/task";
import { TaskViewModel } from "@/modelviews/task";
import { container } from "@/di";
import Image from "next/image";
import { imgAssets } from "@/constants/img";
import { statisticsType } from '@/models/Task';
import { useSetting } from '@/components/mixins/statistic/useSetting';
import Link from 'next/link';
import dayjs from 'dayjs';

export const Statistics = () => {
  const [time, setTime] = useState(["", "", ""]);
  const hooks = React.useContext(HookContext) as TContextHook;
  const taskVM = container.resolve<TaskViewModel>(TaskViewModel);
  taskVM.setHooks(hooks);
  const stateTask = hooks.useSelector((state: any) => state.task);
  const stateProfile = hooks.useSelector((state: any) => state.profile);

  const { settingLab }: any = useSetting()

  const { current_now, minTime, maxTime } = {
                                              current_now: settingLab?.current_now || dayjs(),
                                              minTime: settingLab?.minTime || dayjs(),
                                              maxTime: settingLab?.maxTime || dayjs(),
                                            }
  
  const getDate = (statistics: any) => {
    if(statistics.type == statisticsType.date) {
      return current_now.format("YYYY年MM月DD日")
    }
    return `${moment(statistics.from).format("YYYY年MM月DD日")}〜${moment(statistics.to).format("MM月DD日")}`;
  }
  
  const getSubtract = () => {
    const result = parseFloat(stateTask.statistics.data?.total_point ?? 0) - parseFloat(stateTask.statistics.data?.target_point_now ?? 0);
    const subtractData = Math.round((result ?? 0) * 100) / 100;
  
    return subtractData > 0 ? ('+' + (subtractData).toString()) : (subtractData).toString();
  }

  const getSubtractLengthToFontSize = () => {
    const subtract = getSubtract() || '';
    if(subtract.toString().length <= 4) {    
      return 30;
    }
    return 30 - (subtract.toString().length - 4) * 2;
  }
  
  const getLastDate = (statistics: any) => {
    // if(moment(statistics.to).format("YYYY年MM月DD日") > moment().format("YYYY年MM月DD日"))
    //   return moment().format("M/D")
    // return moment(statistics.to).format("M/D")

    return minTime?.format("M/D")
  }
  
  const getStatistic = async (type: statisticsType, _shift_deadline?: any) => {
    
    if(!stateTask.statistics.data) {
      type = statisticsType.month
    }
    const shift_deadline = (_shift_deadline ?? stateTask.statistics.data?.setting?.shift_deadline ?? "end_of_month") == "end_of_month" ? "01" : _shift_deadline ?? stateTask.statistics.data?.setting?.shift_deadline;
    
    let subMonth = -1;
    if(shift_deadline < parseInt(moment().format("DD")) && shift_deadline != "end_of_month") {
      subMonth = 0;
    }
    


    let from = moment().add(subMonth, "month").format("YYYY-MM-") + shift_deadline;
    if((_shift_deadline ?? stateTask.statistics.data?.setting?.shift_deadline ?? "end_of_month") == "end_of_month") {
      from = moment().startOf("month").format("YYYY-MM-DD");
    }
    else {
      from = moment(from).add(1, "day").format("YYYY-MM-DD");
    }
    const temp = moment(from).add(1, "months").format("YYYY-MM-") + shift_deadline;
    let to = moment(temp).format("YYYY-MM-DD");
    
    if((_shift_deadline ?? stateTask.statistics.data?.setting?.shift_deadline ?? "end_of_month") == "end_of_month") {
      to = moment(temp).add(-1, "day").format("YYYY-MM-DD");
    }
    
    const newState = [
      {
        key: "type",
        val: type
      },
      {
        key: "from",
        val: from
      },
      {
        key: "to",
        val: to
      },
      {
        key: "date",
        val: new Date()
      }
    ];

    
    const res = await taskVM.getStatistic({
      from: type == statisticsType.month ? from : minTime?.format("YYYY-MM-DD HH:mm:ss"),
      to: type == statisticsType.month ? to : maxTime?.format("YYYY-MM-DD HH:mm:ss")
    });
    
    await hooks.dispatch(
      taskSliceAction.updateStatistics([
        {
          key: "data",
          val: {
            ...res.data,
            pause_time: res?.data?.pause_time && {
              ...res?.data?.pause_time,
              start_time_local: moment()
            }
          }
        },
        ...newState
      ])
    )
    
    return res.data?.setting?.shift_deadline;
  }
  
  const changeDate = async (add: number) => {
    if(stateTask.statistics.type == statisticsType.date) {
      const newDate = moment(stateTask.statistics.date).add(add, "days");
      const newState = [
        {
          key: "date",
          val: newDate
        }
      ];
      const res = await taskVM.getStatistic({
        from: newDate.format("YYYY-MM-DD"),
        to: newDate.format("YYYY-MM-DD")
      });
      
      hooks.dispatch(
        taskSliceAction.updateStatistics([
          {
            key: "data",
            val: {
              ...res.data,
              pause_time: res.data.pause_time && {
                ...res.data.pause_time,
                start_time_local: moment()
              }
            }
          },
          ...newState
        ])
      )
    }
    else {
      const shift_deadline = (stateTask.statistics.data?.setting?.shift_deadline ?? "end_of_month") == "end_of_month" ? "01" : stateTask.statistics.data?.setting?.shift_deadline;
      let from = moment(stateTask.statistics.from).add(add, "months").format("YYYY-MM-") + shift_deadline;
      if((stateTask.statistics.data?.setting?.shift_deadline ?? "end_of_month") != "end_of_month") {
        from = moment(from).add(1, "day").format("YYYY-MM-DD");
      }
      const temp = moment(from).add(1, "months").format("YYYY-MM-") + shift_deadline;
      let to = moment(temp).format("YYYY-MM-DD");
      if((stateTask.statistics.data?.setting?.shift_deadline ?? "end_of_month") == "end_of_month") {
        to = moment(temp).add(-1, "days").format("YYYY-MM-DD");
      }
      const newState = [
        {
          key: "from",
          val: from
        },
        {
          key: "to",
          val: to
        }
      ];
      const res = await taskVM.getStatistic({
        from: from,
        to: to
      });
      
      hooks.dispatch(
        taskSliceAction.updateStatistics([
          {
            key: "data",
            val: {
              ...res.data,
              pause_time: res.data.pause_time && {
                ...res.data.pause_time,
                start_time_local: moment()
              }
            }
          },
          ...newState
        ])
      )
    }
  }
  
  const getDefault = async() => {
    const shift_deadline = await getStatistic(stateTask.statistics.type); //get setting
    
    await getStatistic(stateTask.statistics.type, shift_deadline);
  }
  
  useEffect(() => {
    getDefault();
  }, []);
  
  return (
    <div className="blockPerform shadow">
      <Link href={`statistic/staffPoint/${stateProfile.id}?type=${stateTask.statistics.type == statisticsType.month ? 'month' : 'day'}`}>
        <div className="blockPerform-title">
          <h2>個人実績</h2>
          <div className="toggle-button-cover">
            <div className="button-cover">
              <div className="button r" id="button-1">
                <label className="switch mode-switch">
                  <input id="date_mode" type="checkbox" name="date_mode" value="0" checked={stateTask.statistics.type == statisticsType.month}/>
                  <label
                    className={`mode-switch-inner ${stateTask.statistics.type == statisticsType.date ? "left" : "right"}`}
                    htmlFor="date_mode"
                    data-on='月間'
                    data-off='日別'
                    onClick={ (e) => {
                      getStatistic(stateTask.statistics.type == statisticsType.date ? statisticsType.month : statisticsType.date);
                      e.preventDefault();
                    }
                    }
                  ></label>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="blockPerform-content">
          <div className="date dflex dflex_center gap0">
            {/* <span className="ico ico_prev mr10" onClick={(e) => {
              changeDate(-1);
              e.preventDefault();
            }
            }>
              <Image unoptimized={true} src={imgAssets.iconPreSmall} alt=""/>
            </span> */}
            <p>{ getDate(stateTask.statistics) }</p>
            {/* {
              moment(stateTask.statistics.type == statisticsType.date ? stateTask.statistics.date : stateTask.statistics.to).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ?
              <span className="ico ico_next ml10" onClick={(e) => {
                changeDate(1)
              }
              }>
                <Image unoptimized={true} src={imgAssets.iconNextSmall} alt=""/>
              </span>
              :
              <span className="ico ico_next ml10">
                <Image unoptimized={true} src={imgAssets.nextGray} alt=""/>
              </span>
            } */}
            
          </div>
          {
            stateTask.statistics.type == statisticsType.date ?
            <div className="content">
              <div className="dflex">
                <div className="item">
                  <p className="text_bold">目標</p>
                  {
                    stateTask.statistics.data?.target_point_now &&
                    <p className="num fontArial">{ Math.round(stateTask.statistics.data?.target_point_now * 100) / 100 }<small>Pt</small></p>
                  }
                </div>
                <div className="item">
                  <p className="text_bold">実績</p>
                  {
                    stateTask.statistics.data?.total_point &&
                    <p className="num fontArial">{ Math.round(stateTask.statistics.data?.total_point * 100) / 100}<small>Pt</small></p>
                  }
                </div>
                <div className="item total">
                  <p className={`num clpage fontArial ${parseFloat(getSubtract()) < 0 ? 'red' : ''}`} style={{fontSize: getSubtractLengthToFontSize()+'px'}}>{ getSubtract() }<small>Pt</small></p>
                </div>
              </div>
              {/* <div className="text_right btnbtom">
                <button className="btn btn-primary">実績確認</button>
              </div> */}
            </div>
            :
            <div className="content">
              <div className="dflex">
                <div className="item">
                  <p className="text_bold">月間目標</p>
                  {
                    stateTask.statistics.data?.target_point_month && 
                    <p className="num fontArial">{ Math.round(stateTask.statistics.data?.target_point_month * 100) / 100 }<small>Pt</small></p>
                  }
                </div>
              </div>
              <div className="dflex">
                <div className="item">
                  {
                    stateTask.statistics.data?.total_date &&
                    <p className="text_bold">{ stateTask.statistics.data?.total_date ?? 0 }日間経過目標</p>
                  }
                  {
                    stateTask.statistics.data?.target_point_now &&
                    <p className="num fontArial">{ Math.round(stateTask.statistics.data?.target_point_now * 100) / 100 }<small>Pt</small></p>
                  }
                </div>
                <div className="item">
                  <p className="text_bold">実績（{getLastDate(stateTask.statistics)}現在）</p>
                  {
                    stateTask.statistics.data?.total_point &&
                    <p className="num fontArial">{ Math.round(stateTask.statistics.data?.total_point * 100) / 100}<small>Pt</small></p> 
                  }
                </div>
                <div className="item total">
                  {
                    stateTask.statistics.data?.total_point &&
                    <p className={`num clpage fontArial ${parseFloat(getSubtract()) < 0 ? 'red' : ''}`}>{ getSubtract() }<small>Pt</small></p>
                  }
                </div>
              </div>
              {/* <div className="text_right btnbtom">
                <button className="btn btn-primary">実績確認</button>
              </div> */}
            </div>
          }
          
        </div>
      </Link>
    </div>
  );
};
