import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";

import { BaseViewModel } from "@/modelviews/BaseViewModel";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { type ICardRepository } from "@/datasource/repositories/card/cardRepository";
import { ICardParams } from "@/models/card/Card";

@injectable()
export class CardViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.CardRepository) private cardRepository: ICardRepository
  ) {
    super();
  }
  async addCard(params: ICardParams): Promise<IDataResponse<any>> {    
    const res = await this.withLoading(() => this.cardRepository.addCard(params));
    if (res.success) {      
      this.setMsg('登録が完了しました', 1);
      this.hooks.router.push('/');
    } else {
      this.setMsg(res.message || '登録中にエラーが発生しました', -1);
    }
    return res;
  }
}
