import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FormValidation from '@/utils/validate';
import internal from 'stream';
//import { IUser } from '@/models/User';

const initialState: any = {
  id: null,
  lab_id: null,
  child_lab_id: null,
  clinic_id: null,
  user_type: '',
  user_role: '',
  name_sei: '',
  name_mei: '',
  password_length: null,
  qr_token: '',
  employee_barcode: '',
  email: '',
  switch_role: null,
  payment_status: '',
  withdraw: '',
  parent_id: null,
  pass_code_status: false,
  pass_code_length: null,
  Lab: {},
  Permission: []
};
export const profileSlice = createSlice({
  name: 'profile',
  initialState,

  reducers: {
    setInfo(state, action: PayloadAction<Partial<any>>) {
      Object.assign(state, action.payload);
    },
    resetInfo(state) {      
      Object.assign(state, initialState );
    }
  },
});

const profileReducer = profileSlice.reducer;
const { setInfo, resetInfo } = profileSlice.actions;
export { setInfo, resetInfo };
