import Link from "next/link";
import Image from "next/image";
import { imgAssets } from "@/constants/img";
import { orderSliceAction } from "@/store/order";
import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { orderProcessSliceAction } from "@/store/order/process";
import { orderHistorySliceAction } from "@/store/order/history";
import { setBackHistoryUrl } from "@/store/global";

export const Header = (props: any) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const { pathname, query } = hooks.router;
  const stateOrder = hooks.useSelector((state: any) => state.order);
  const orderLog = hooks.useSelector(
    (state: any) => state.order.orderLogChange
  );

  const handleResetState = () => {

    hooks.dispatch(orderHistorySliceAction.reset())
  }

  const redirectBackLink = (link = null) => {
    const linkBack: any = link || "/";

    if (orderLog.length > 0) {
      hooks.dispatch(
        orderSliceAction.showModal({
          key: "showConfirmReverPage",
          val: !stateOrder.modals.showConfirmReverPage,
        })
      );
      hooks.dispatch(orderSliceAction.setLinkReverPage({ value: linkBack }));
      return false;
    }

    // redirect topage
    if (linkBack === "/") {
      // back 
      if(query.taskId && query.taskId !== "")
      {        
        hooks.router.push(linkBack)
        return;
      }

      handleResetState();
      hooks.dispatch(orderSliceAction.setLinkReverPage({ value: linkBack }));
      hooks.dispatch(orderSliceAction.reset());
      hooks.dispatch(orderProcessSliceAction.reset());
      hooks.dispatch(orderSliceAction.addOrderLog({ request: "rest" }));
      hooks.dispatch(setBackHistoryUrl(""))


      hooks.router.push(linkBack);
      return false;
    } else if (linkBack) {

      if(query.taskId && query.taskId !== "")
      {
        hooks.router.push(linkBack)
        return;
      }

      hooks.dispatch(setBackHistoryUrl(""))
      hooks.router.push(linkBack);
      
      return;
    }

    hooks.router.back();
  };

  const getTextRight = (query: any) => {
    if(query.additional)
    {
      return "工程を追加する指示書"
    }else if(query.taskId)
    {
      return "追加する指示書"
    }
    return "読み込んだ指示書";
  }


  return (
    <div className="head dflex">
      <div className="head_left">
        <div className="logo" >
          <Link href={"/"} onClick={(e: any) => { e.preventDefault(); redirectBackLink() } } ><Image src={imgAssets.logoWhite} alt="" /></Link>
        </div>
        <div className="titleHead">
          <button onClick={(e: any) => redirectBackLink(props.linkBack)}>
            <Image src={props.imgAssets.iconArrowBack2} alt="back" />{" "}
          </button>
          <div className={props.tooltip ? "hasTooltip" : ""}>
            <h2 className="title">
              {props?.title}
            </h2>
            {props.tooltip && (
              <OverlayTrigger
                placement="right-start"
                overlay={<Tooltip id="tooltip-2"> {props.tooltip()} </Tooltip>}
              >
                <span className="ico_tooltip cursorPointer">
                  {" "}
                  <Image
                    className="mr15"
                    src={imgAssets.iconQuestion}
                    alt="iconQuestion"
                  />
                </span>
              </OverlayTrigger>
            )}
          </div>
        </div>
        {(props.custumHead && props?.custumHead()) || ""}
      </div>
      <div className={props.vsClass ? props?.vsClass : `head_right dflex`}>
        <div
          className={
            "head_right-btn " + (props?.prefix && !props?.vsLeft && "vshidden")
          }
        >
          {props?.vsLeft ? (
            <div className="head_right-btn"> {props?.vsLeft()} </div>
          ) : (
            <>
              <button
                className="btn btn-primary btn-icon ico-camera mr05"
                type="button"
                onClick={(e) =>
                  hooks.dispatch(
                    orderSliceAction.showModal({
                      key: "showScan",
                      val: !stateOrder.modals.showScan,
                    })
                  )
                }
              >
                カメラ読込
              </button>
              <button
                onClick={(e) =>
                  hooks.dispatch(
                    orderSliceAction.showModal({
                      key: "showClinic",
                      val: !stateOrder.modals.showClinic,
                    })
                  )
                }
                type="button"
                className="btn btn-primary btn-icon ico-instruction "
              >
                指示書番号発行
              </button>
            </>
          )}
        </div>

        <div className="head_right-txt">
          {props?.vsRight ? (
            <div className="head_right-btn"> {props?.vsRight()} </div>
          ) : (
            <>
              <p className="text_right text_bold">{getTextRight(query)}</p>
              <div className="num clpage fontArial text_bold text_right">
                {pathname.includes("/order/process") &&
                stateOrder.orderProgresses.length === 0 ? (
                  <b>-</b>
                ) : (
                  stateOrder.orderProgresses.length
                )}
                <small>枚</small>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
