
import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface ITaskService {
  getStatistic(params: any): Promise<IDataResponse<any>>;
  getTask(params: any): Promise<IDataResponse<any>>;
  getHistoryTasks(params: any): Promise<IDataResponse<any>>;
  getFreeTime(params: any): Promise<IDataResponse<any>>;
  fetchOne(taskId: Number): Promise<IDataResponse<any>>;
  getTaskLog(params: any): Promise<IDataResponse<any>>;
  startTask(taskId: number): Promise<IDataResponse<any>>;
  pauseTask(taskId: number, countTime: number): Promise<IDataResponse<any>>;
  pauseTime(type: string): Promise<IDataResponse<any>>;
  startTime(): Promise<IDataResponse<any>>;
  doneTask(taskId: number, data: any): Promise<IDataResponse<any>>;
  deleteTask(taskId: number): Promise<IDataResponse<any>>;
  deleteOrders(taskId: any, orderIds : any ): Promise<IDataResponse<any>>;
  updateOrdersTask(taskId: any, data : any ): Promise<IDataResponse<any>>;
  fetchDuplicateTask( params: any ): Promise<IDataResponse<any>>;
  updateDuplicateTask( data: any ): Promise<IDataResponse<any>>;
  updateTaskLog( taskId: any, data: any ): Promise<IDataResponse<any>>;
  addTaskLog( data: any ): Promise<IDataResponse<any>>;
  checkProductIncludeProcess(taskId: number, params: any): Promise<IDataResponse<any>>;
  statusTaskOverLap(params: any): Promise<IDataResponse<any>>;
}

@injectable()
export class TaskService {
  private prefix: string;
  private prefixTask: string;

  constructor() {
    this.prefix = '/labs/processes/tasks';
    this.prefixTask = '/employees/tasks/timelines/';
  }

  async statusTaskOverLap(params: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefixTask}/check-overlapping`, params);
  }

  async addTaskLog(data: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/logs`, data);
  }

  async updateTaskLog(taskId: any, data: any): Promise<IDataResponse<any>> {
    return await axiosService.put(`${this.prefix}/${taskId}/logs`, data);
  }

  async updateDuplicateTask(data: any): Promise<IDataResponse<any>> {
    return await axiosService.put(`${this.prefix}/duplicate`, data);
  }

  async fetchDuplicateTask(params: any): Promise<IDataResponse<any>> {
    return await axiosService.get(`${this.prefix}/duplicate`, { params });
  }

  async getStatistic(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/statistics`, {
      params: params,
    });
    return response;
  }

  async updateOrdersTask(taskId: any, data: any): Promise<IDataResponse<any>> {
    return await axiosService.put(`${this.prefix}/${taskId}/orders`, data);
  }

  async deleteTask(taskId: any): Promise<IDataResponse<any>> {
    return await axiosService.delete(`${this.prefix}/${taskId}`);
  }

  async deleteOrders(taskId: any, orderIds: any): Promise<IDataResponse<any>> {
    return await axiosService.delete(`${this.prefix}/${taskId}/orders`,{data:orderIds});
  }

  async fetchOne(taskId: Number): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/${taskId}`);
    return response;
  }

  async getTask(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }

  async getHistoryTasks(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/history`, {
      params: params,
    });
    return response;
  }

  async getFreeTime(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/free-time`, {
      params: params,
    });
    return response;
  }

  async getTaskLog(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/logs`, {
      params: params,
    });
    return response;
  }

  async startTask(taskId: number): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/${taskId}/start`, {});
    return response;
  }

  async pauseTask(taskId: number, countTime: number): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/${taskId}/pause`, {count_time: countTime});
    return response;
  }

  async pauseTime(type: string): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`/labs/processes/pause`, {type});
    return response;
  }

  async startTime(): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`/labs/processes/start`, {});
    return response;
  }

  async doneTask(taskId: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/${taskId}/done`, data);
    return response;
  }

  async checkProductIncludeProcess(taskId: number, params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/${taskId}/check-products`, {
      params: params
    });
    return response;
  }
}