import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import Image from "next/image";
import { useFilter } from "@/components/mixins/order/useFilter";
import { orderSliceAction } from "@/store/order";
import { mapOrder, randomString } from "@/helpers";
import { OrderViewModel } from "@/modelviews/order";
import { container } from "@/di";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { imgAssets } from "@/constants/img";
import { mapSaveLogSystem, ScreenEnum } from "@/helpers/log";
import { SettingEmployeeViewModel } from "@/modelviews/setting/employee";

const initialState = { count: 1 };

const reducer = (state: any, action: any) => {
  let number: number;

  switch (action.type) {
    case "increment":
      number: number = state.count + 1;
      return { count: number };
    case "decrement":
      number: number = state.count - 1;
      return { count: number > 0 ? number : 1 };
    default:
      throw new Error();
  }
};

export const ModalClinic = ({ ...props }: any) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const employeeSettingVM = container.resolve<SettingEmployeeViewModel>(SettingEmployeeViewModel);
  const orderVM = container.resolve<OrderViewModel>(OrderViewModel);
  employeeSettingVM.setHooks(hooks);
  orderVM.setHooks(hooks);
  const stateOrder = hooks.useSelector((state: any) => state.order);
  const profile = hooks.useSelector((state: any) => state.profile);
  const { formFieldSubmit, setFormFields, items, handleLoadMore } = useFilter();
  const [checkClinic, setClinic]: any = React.useState([]);
  const [number, dispatchNumber] = React.useReducer(reducer, initialState);

  const onCheckedClinic = (event: any) => {
    let { value }: any = event.target;
    return setClinic([Number(value)]);
  };

  const {handlePrintHeader, hiddenOrderFlg} = props

  const handleLogSystem = (orderPrints: any) =>
  {
    let screen : ScreenEnum = ScreenEnum.createOrder;
    let dataLog = mapSaveLogSystem(screen, orderPrints)
    employeeSettingVM.updateLog(dataLog).then((res: any) => {

    })
  }

  const handleSubmit = async () => {
    const clinic: any = items.find((val: any) =>
      checkClinic.includes(Number(val.id))
    );
    if (!clinic) {
      return;
    }

    let data = {
      clinic_id: clinic.Clinic.id,
      quantity: number.count,
    };

    await orderVM.bulkOrders(data).then((res: any) => {
      if (res.success) {
        if(res.data.length > 0) {
          // handle print order
          let orderPrints  = res.data.map((order: any) =>  mapOrder(order))
          hooks.dispatch(orderSliceAction.setOrderPrints(orderPrints))
          setTimeout(() => {
            handlePrintHeader() 
          }, 1000)

          // add order block right
          if(!hiddenOrderFlg)
          {
            res.data.forEach((order: any, index: number) => {
              let orderDataMap = mapOrder(order);
              hooks.dispatch(
                orderSliceAction.addOrder(orderDataMap)
              );
            }); 
          }

          // handle log system
          handleLogSystem(orderPrints)
        }
      }
      hooks.dispatch(
        orderSliceAction.showModal({
          key: "showClinic",
          val: !stateOrder.modals.showClinic,
        })
      );
    });

    // ** is select first order
    if( props?.isSelectFirstOrder  )
    {
      const stateOrderPro = hooks.getState()?.order?.orderProgresses || []

      if( stateOrderPro?.length > 0 )
        hooks.dispatch( orderSliceAction.selectOrderProgress( { data: { ...stateOrderPro[ stateOrderPro?.length - 1 ] }, index: (stateOrderPro?.length - 1) } )  )
    }
    
  };

  return (
    <div className="modal-open">
      <div className="modal fade show" id="instruction">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
            {/* title-ico-question  */}
              <h4 className="modal-title text_center">
                指示書番号発行
              </h4>
              &nbsp;&nbsp;
              <OverlayTrigger
                placement="right-start"
                overlay={<Tooltip id="tooltip-2"> 
                          <>
                            歯科医院を選択して指示書番号を発行します。<br/>
                            指示書番号を発行するとレシートにバーコード <br/>
                            が印字されるので、指示書とセットにしてくだ <br/>
                            さい。
                          </> 
                          </Tooltip>}
              >
                <span className="ico_tooltip cursorPointer">
                  <Image
                    className="mr15"
                    src={imgAssets.iconQuestion}
                    alt="iconQuestion"
                  />
                </span>
              </OverlayTrigger>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) =>
                  hooks.dispatch(
                    orderSliceAction.showModal({
                      key: "showClinic",
                      val: !stateOrder.modals.showClinic,
                    })
                  )
                }
              >
                <Image src={imgAssets.iconClose} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <div className="filter">
                <div className="form-group-ins form-check-custom" >
                  <input
                    className="form-control form-check-primary form-check-input"
                    type="checkbox"
                    id="dummy1"
                    name="ios_flg"
                    checked={formFieldSubmit?.ios_flg || false}
                    onChange={setFormFields}
                  />
                  <label className="form-check-label" htmlFor="dummy1" >IOS対応</label>
                </div>
                <div className="form-group-ins form-check-custom">
                  <input
                    className="form-control form-check-primary form-check-input"
                    type="checkbox"
                    id="dummy2"
                    name="evm_publish"
                    value="1"
                    checked={ (formFieldSubmit?.evm_publish > 0) || false}
                    onChange={setFormFields}
                  />
                  <label className="form-check-label" htmlFor="dummy2" >指示書番号未登録</label>
                </div>
                <div className="form-group mr20 ml30">
                  <input
                    className="form-control searchClinic"
                    placeholder="医院コード・医院名で検索"
                    name="keyword"
                    value={formFieldSubmit?.keyword || ""}
                    onChange={setFormFields}
                  />
                </div>
                {!items.length && (
                  <div className="txt_error clred text_bold">
                    検索結果がありません。条件を変更してください。
                  </div>
                )}
                <div className="txtresult text_bold">
                  {items.length} <span>件</span>
                </div>
              </div>
              <div className="overBlock">
                <div className={items.length >= 8 ? 'issuedList issuedListMany' : 'issuedList'} onScrollCapture={handleLoadMore}>
                  {items.map((val: any, index: number) => (
                    <label
                      className={
                        "item " + (checkClinic.includes(val.id) ? "active" : "")
                      }
                      key={index + "ids" + val.id}
                      htmlFor={"itemId" + val.id}
                    >
                      <div className="item_head">
                        <p className="num fontArial">{val.clinic_code}</p>
                        <div className="label">
                          
                          {
                            (val?.Clinic?.EVMOrder.length <= 0) && (<label style={{ color: "#ED5555", background: "#FFFAFA" }} > 指示書番号未登録 </label>)
                          }
                          
                          {val?.ios_flg && (
                            <label
                              style={{
                                color: "#518CED",
                                background: "#EAF4FF",
                              }}
                            >
                              
                              IOS
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="item_name">{val.Clinic?.clinic_name}</div>
                      <div className="item_address">
                          〒 { val.Clinic?.clinic_zip }<br/>
                            { val.Clinic?.Pref ? val.Clinic?.Pref.name : '' }&nbsp;
                            { val.Clinic?.City ? val.Clinic?.City.name: '' }&nbsp;
                            { val.Clinic?.clinic_address }
                            {/* { val.Clinic?.clinic_building }<br/> */}
                            {/*<br/> { val.Clinic?.clinic_phone } */}
                        {/* {val.Clinic?.clinic_address} */}
                      </div>
                      <input
                        type="radio"
                        name="clinic_id"
                        id={"itemId" + val.id}
                        value={val.id}
                        className="hide"
                        checked={checkClinic.includes(val.id)}
                        onChange={onCheckedClinic}
                      />
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="modal-footer dflex">
              <div className="note">
                {!checkClinic.length ? (
                  <p className="f20 clred text_bold">
                    歯科医院を選択してください
                  </p>
                ) : (
                  <p className="f20 text_bold">
                    {
                      items.find((val: any) =>
                        checkClinic.includes(Number(val.id))
                      )?.Clinic?.clinic_name
                    }
                  </p>
                )}
                <p className="f14 clred text_bold">
                  ※指示書番号発行するとバーコードが印刷されます。必ず指示書とセットにしてください。
                </p>
              </div>
              <div className="issued">
                <p className="f14">発行数</p>
                <div className="number dflex">
                  <button
                    className="btnminus"
                    onClick={() => dispatchNumber({ type: "decrement" })}
                  >
                    
                    <Image src={imgAssets.iconMinus} alt="" />
                  </button>
                  <span className="num fontArial">{number?.count}</span>
                  <button
                    className={`btnadd ${number.count >= 100 ?  "disabled" : ""}`}
                    onClick={() => dispatchNumber({ type: "increment" })}
                  >
                    
                    <Image src={imgAssets.iconPlus} alt="" />
                  </button>
                </div>
              </div>
              <div className="button">
                <button
                  // className="btn btn-gray"
                  className={
                    "btn " + (checkClinic.length ? "btn-primary" : "btn-gray")
                  }
                  disabled={checkClinic.length <= 0}
                  type="button"
                  onClick={(e) => {handleSubmit()}}
                >
                  指示書番号発行
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
