import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRegisterFormFields, IRegisterSubmitField } from "@models/Register";
import FormValidation from "@/utils/validate";
import { fieldName } from "@/constants/validationFieldName";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { ILabInfo } from "@/models/Lab";
import { number } from "yup";

interface orderManageSlice {

    data: {
      items: any,
      current_page: number,
      last_page: number,
      sort: object,
      total: number,
      filter: object,
    },
    selects: any
}

const initialState: orderManageSlice = {

    data: {
      items: [],
      current_page: 1,
      last_page: 1,
      sort: {},
      total: 0,
      filter: {},
    },
    selects: []
};

export const orderManageSlice = createSlice({

  name: "orderManage",
  initialState,
  reducers: {

    fetchAll( state, action: PayloadAction<any> )
    {
      const { data, last_page, current_page, filter, total, sort }: { data: any, last_page: number, current_page: number, filter: any, total: number, sort: object } =  action.payload;
      state.data = { items: data, current_page, last_page, sort, total, filter };
    },
    setSelects( state, action: PayloadAction<any> )
    {
      const { data }: { data: any } =  action.payload;
      state.selects = [ ...data ]
    }

  }

});

const authReducer = orderManageSlice.reducer;
const { fetchAll, setSelects  } = orderManageSlice.actions;
export { fetchAll, setSelects, authReducer };
