import { HookContext, TContextHook } from "@/context/hook";
import { container } from "@/di";
import { IRegisterSubmitField } from "@/models/Register";
import { RegisterViewModel } from "@/modelviews/register";
import { LaboViewModel } from "@/modelviews/labo";
import React from "react";
import { forEach } from "lodash";
import {
  setFromFiledCustomSubmit,
  setFromFiledCustom,
  setFormFieldLaboSubmit,
} from "@/store/register";
import { ImplantViewModel } from "@/modelviews/setting/implant";
import { MaterialViewModel } from "@/modelviews/setting/material";
import { setModal } from "@/store/global";
import { setLoadData } from "@/store/setting";
import { SettingProcessViewModel } from "@/modelviews/setting/process";
import { setLoading } from "@/store/global";
import { setVariationGlobal } from "@/store/global";
import { setMsg } from "@/store/global";

export const useCategories = (
  productIds: any = null,
  scanOrderFlg: boolean = true,
  type: any = null,
  orderIds: any = [],
  isFetch: boolean = false
) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const registerViewModel =
    container.resolve<RegisterViewModel>(RegisterViewModel);
  registerViewModel.setHooks(hooks);

  const settingProcessViewModel = container.resolve<SettingProcessViewModel>(
    SettingProcessViewModel
  );
  settingProcessViewModel.setHooks(hooks);

  const categoriesFavorite =
    hooks.useSelector((state: any) => state.global?.categoriesFv) || [];
  let { categories } = hooks.useSelector((state: any) => state.setting);

  const {
    modals: { showMaterial },
  } = hooks.useSelector((state: any) => state.order);

  const fetchs = async () => {
    hooks.dispatch(setLoading(true));
    const res = await settingProcessViewModel.fetchCategories({
      includes: "Process",
      scanOrderFlg,
      productIds,
      type,
      orderIds,
      deletable: true,
      cud_childs_able: true,
    });

    if (res) hooks.dispatch(setLoadData({ ...res, type: "categories" }));

    return hooks.dispatch(setLoading(false));
  };

  hooks.useEffect(() => {
    if (!isFetch) fetchs();
  }, [type]);

  const fetchCategories = async (type: any) => {
    const res = await settingProcessViewModel.fetchCategories({
                                                                includes: "Process",
                                                                cud_childs_able: true,
                                                                limit: -1,
                                                                type,
                                                              });
    
    if ( res.length )
      hooks.dispatch( setVariationGlobal({ data: res, field: "categoriesFv" }) );
  };

  const handleFavoriteProcess = 
    async (data: any) => {
      const res: any = await settingProcessViewModel.updatFavoriteProcess(data);
      if(res.success)
      {
        hooks.dispatch(setMsg({ text: res.message, status: res.success }));
        hooks.dispatch( setModal( { modalId: 'Selection', isCloseAll: false, isShow: false  } ) )
      }

      return res;
    }

  return {
    categories,
    categoriesFavorite,
    fetchCategories,
    handleFavoriteProcess,
    fetchs,
  };
};
