import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";
import { IRegisterParams, IRegisterSubmitField } from "@models/Register";
import { ICardParams } from "@/models/card/Card";


export interface ICardService {
  addCard(params: ICardParams): Promise<IDataResponse<any>>;  
}
@injectable()
export class CardService implements ICardService {
  private prefix: string;
  constructor() {
    this.prefix = '/profile';
  }
  async addCard(params: ICardParams): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/update-card`, params);    
    return response;
  }  
}