import { ITask, ITaskStatistic, statisticsType, TaskModal } from "@/models/Task";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { stat } from "fs";
import _ from "lodash";
import moment from "moment";

interface IStatistics {
  type: statisticsType,
  data?: ITaskStatistic,
  date?: Date,
  from?: Date,
  to?: Date,
  currentMonth?: number,
}

interface ITaskStore {
  data?: ITask[],
  currentPage: number,
  lastPage: number,
  limit: number
}

interface IState {
  statistics: IStatistics;
  task: ITaskStore,
  popUp: {
    modal: TaskModal | null,
    currentTask: ITask | null
  },
  order: {
    orderProgresses: Array<any>,
    modals: any,
    selectedOrder: number[]
  },
  logs: any[],
  showSideBar: boolean,
  showLogoutPopup: boolean
}

const initialState: IState = {
  statistics: {
    type: statisticsType.month,
    date: new Date()
  },
  task: {
    currentPage: 0,
    lastPage: 1,
    limit: 6
  },
  popUp: {
    modal: null,
    currentTask: null 
  },
  order: {
    orderProgresses: [],
    modals: {},
    selectedOrder: []
  },
  logs: [],
  showSideBar: false,
  showLogoutPopup: false
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    updateStatistics(state, action) {      
      let data = action.payload;
      
      for(let item of data) {
        state.statistics = {
          ...state.statistics,
          [item.key]: item.val
        }
      }
    },
    
    updateTask(state, action) {      
      let data = action.payload;
      for(let item of data) {
        state.task = {
          ...state.task,
          [item.key]: item.val
        }
      }
    },
    
    updateOneTask(state, action) {      
      let task = action.payload;
      
      for(let index in state.task.data) {
        let numberIndex = parseInt(index);
        if(state.task.data[numberIndex].id == task?.id) {
          state.task.data[numberIndex] = {
            ...task,
            clientTime: moment()
          };
        }
      }
    },
    
    updateStore(state, action) {      
      let data = action.payload;
      for(let item of data) {
        state.popUp = {
          ...state.popUp,
          [item.key]: item.val
        }
      }
    },
    
    addOrder(state, action) {
      let data = action.payload;
      let orderProgresses = state.order.orderProgresses;
      let newOrder = {
        id: data.id,
        printQuantity: data.printQuantity,
        clinic: data.clinic,
        patientName: data.patientName,
        appointmentDate: data.appointmentDate,
        appointmentTime: data.appointmentTime,
        hasChange: data.hasChange,
        instructionStatus: data.instructionStatus,
        orderProducts: data.orderProducts,
        orderCode: data.orderCode,
      };
      orderProgresses.push(newOrder);
      state.order.orderProgresses = [...orderProgresses];
    },
    
    updateSelected(state, action) {      
      let data = action.payload;
      state.order.selectedOrder = data
    },
    
    clearOrder(state, action) {      
      let data = action.payload;
      state.order.orderProgresses = []
    },
    
    saveLogs(state, action) {      
      let data = action.payload;
      state.logs = data
    },
    
    showSidebar(state, action) {
      let data = action.payload;
      state.showSideBar = data
    },
    
    showLogoutPopup(state, action) {
      let data = action.payload;
      state.showLogoutPopup = data
    },
  },
  extraReducers: (builder) => {},
});

export default taskSlice;
export const taskReducer = taskSlice.reducer;
export const taskSliceAction = taskSlice.actions;
