import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";
import { type ISettingPermissionService } from "@/datasource/services/setting/permission/permissionService";
import { IPermissionParams } from "@/models/setting/Permission";

export interface ISettingPermissionRepository {
  getAllPermission(params?: any): Promise<IDataResponse<any>>;
  createPermission(params: IPermissionParams): Promise<IDataResponse<any>>; 
}
@injectable()
export class SettingPermissionRepository implements ISettingPermissionRepository {
  constructor(
    @inject(TYPES.SettingPermissionService) private settingPermissionService: ISettingPermissionService,
  ) {}  
  async getAllPermission(params?: any): Promise<IDataResponse<any>> {
    let res = await this.settingPermissionService.getAllPermission(params);
    return res;
  }
  async createPermission(params: IPermissionParams): Promise<IDataResponse<any>> {
    let res = await this.settingPermissionService.createPermission(params);
    return res;
  }
}
