import * as React from "react";

import { SlotProvider } from "../base/SlotProvider";
interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export const ComponentProvider = (props: Props) => {
  return (
    <section className="main-business">
      <section className="layoutSecond">
        <SlotProvider
          slots={{
            header: {
              className: "header",
              data: {},
            },
            content: {
              className: "content",
            },
          }}
        >
          {props.children}
        </SlotProvider>
      </section>
    </section>
  );
};
