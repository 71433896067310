import { IAuthRepository } from "@repositories/interface/IAuthRepository";
import { AuthRepository } from "@repositories/auth";
import { Container } from "inversify";
import { IAuthService } from "@services/interface/IAuthService";
import "reflect-metadata";
import { AuthService } from "@services/auth";
import { TYPES } from "@di/types";
import { AuthViewModel } from "@/modelviews/auth";
import { HookContext, TContextHook, useHook } from "@/context/hook";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import {
  IRegisterService,
  RegisterService,
} from "@/datasource/services/register";
import {
  IRegisterRepository,
  RegisterRepository,
} from "@/datasource/repositories/register";
import { RegisterViewModel } from "@/modelviews/register";
import {
  IVerifyService,
  VerifyService,
} from "@/datasource/services/verifyService";
import {
  ISearchLabService,
  SearchLabService,
} from "@/datasource/services/searchLabService";
import {
  IPrefCityServices,
  PrefCityServices,
} from "@/datasource/services/prefcity/prefcityService";
import {
  IPrefCityRepository,
  PrefCityRepository,
} from "@/datasource/repositories/prefcity/prefcityRepository";
import { PrefCityViewModel } from "@/modelviews/prefcity";
// labo
import { LaboViewModel } from "@/modelviews/labo";
import { LaboService } from "@/datasource/services/labo";

import {
  ISettingProcessRepository,
  SettingProcessRepository,
} from "@/datasource/repositories/setting/process";
import { SettingProcessService } from "@/datasource/services/setting/process";

import {
  ISettingIconRepository,
  SettingIconRepository,
} from "@/datasource/repositories/setting/icon";
import { SettingIconService } from "@/datasource/services/setting/icon";

import {
  ISettingProductRepository,
  SettingProductRepository,
} from "@/datasource/repositories/setting/product";
import { SettingProductService } from "@/datasource/services/setting/product";

import {
  ISettingLabRepository,
  SettingLabRepository,
} from "@/datasource/repositories/setting/labo/setting";

import { LaboRepository } from "@/datasource/repositories/setting/labo";
// branch
import { BranchRepository } from "@/datasource/repositories/setting/labo/branch";
import { BranchService } from "@/datasource/services/labo/branch";
import { BranchViewModel } from "@/modelviews/labo/branch";
// department
import { DepartmentService } from "@/datasource/services/labo/department";
import { DepartmentRepository } from "@/datasource/repositories/setting/labo/department";
import { DepartmentViewModel } from "@/modelviews/labo/department";
// Reason
import { ReasonService } from "@/datasource/services/setting/reason";
import { ReasonRepository } from "@/datasource/repositories/setting/reason";
import { ReasonViewModel } from "@/modelviews/setting/reason";
//Material
import { MaterialService } from "@/datasource/services/setting/material";
import { MaterialRepository } from "@/datasource/repositories/setting/material";
import { MaterialViewModel } from "@/modelviews/setting/material";

import { VariationService } from "@/datasource/services/setting/variation";
import { VariationsRepository } from "@/datasource/repositories/setting/variations";
import { VariationsViewModel } from "@/modelviews/setting/variations";

//Implant
import { ImplantService } from "@/datasource/services/setting/implant";
import { ImplantRepository } from "@/datasource/repositories/setting/implant";
import { ImplantViewModel } from "@/modelviews/setting/implant";

// statistic clinic
import { StatisticClinicService } from "@/datasource/services/statistic/clinic";
import { StatisticClinicViewModel } from "@/modelviews/statistic/clinic";
import { StatisticClinicRepository } from "@/datasource/repositories/statistic/clinic";

// Statistic Staff
import { StatisticStaffService } from "@/datasource/services/statistic/staff";
import { StatisticStaffViewModel } from "@/modelviews/statistic/staff";
import { StatisticStaffRepository } from "@/datasource/repositories/statistic/staff";

// Statistic Staff Point
import { IStatisticStaffPointRepository } from "@/datasource/repositories/statistic/staffPoint";
import { StatisticStaffPointService } from "@/datasource/services/statistic/staffPoint";

// Statistic Department
import { IStatisticDepartmentRepository } from "@/datasource/repositories/statistic/department";
import { StatisticDepartmentService } from "@/datasource/services/statistic/department";

// Statistic Week Point
import { IStatisticWeekPointRepository } from "@/datasource/repositories/statistic/weekPoint";
import { StatisticWeekPointService } from "@/datasource/services/statistic/weekPoint";

// Statistic
import { IStatisticRepository } from "@/datasource/repositories/statistic/index";
import { StatisticService } from "@/datasource/services/statistic/index";

import {
  CheckExistInfoService,
  ICheckExistInfoService,
} from "@/datasource/services/checkExistInfo";
import {
  CardService,
  ICardService,
} from "@/datasource/services/card/cardService";
import {
  CardRepository,
  ICardRepository,
} from "@/datasource/repositories/card/cardRepository";
import { CardViewModel } from "@/modelviews/card";

import { PartnerService } from "@/datasource/services/setting/partner";
import { PartnerRepository } from "@/datasource/repositories/setting/partner";
import { PartnerViewModel } from "@/modelviews/setting/partner";

import {
  ISettingEmployeeService,
  SettingEmployeeService,
} from "@/datasource/services/setting/employee";

import { SettingEmployeeViewModel } from "@/modelviews/setting/employee";
import {
  ISettingEmployeeRepository,
  SettingEmployeeRepository,
} from "@/datasource/repositories/setting/employee";
import {
  ISettingPermissionService,
  SettingPermissionService,
} from "@/datasource/services/setting/permission/permissionService";
import {
  ISettingPermissionRepository,
  SettingPermissionRepository,
} from "@/datasource/repositories/setting/permission/permissionRepository";
import { SettingBranchViewModel } from "@/modelviews/setting/branch";
import { SettingDepartmentViewModel } from "@/modelviews/setting/department";
import { SettingLabService } from "@/datasource/services/labo/setting";

// order
import {
  IOrderRepository,
  OrderRepository,
} from "@/datasource/repositories/order";
import { OrderService } from "@/datasource/services/order";

import {
  ITaskRepository,
  TaskRepository,
} from "@/datasource/repositories/task";
import { TaskService } from "@/datasource/services/task";
// order
import {
  IOrderGroupRepository,
  OrderGroupRepository,
} from "@/datasource/repositories/order/group";
import { OrderGroupService } from "@/datasource/services/order/group";

//base
const container = new Container();
container.bind<BaseViewModel>(BaseViewModel).toSelf();

// statistic
container.bind(TYPES.StatisticClinicRepository).to(StatisticClinicRepository);
container.bind(TYPES.StatisticClinicService).to(StatisticClinicService);
container.bind<StatisticClinicViewModel>(StatisticClinicViewModel).toSelf();

container.bind(TYPES.StatisticStaffRepository).to(StatisticStaffRepository);
container.bind(TYPES.StatisticStaffService).to(StatisticStaffService);
container.bind<StatisticStaffViewModel>(StatisticStaffViewModel).toSelf();

container
  .bind<IStatisticStaffPointRepository>(TYPES.StatisticStaffPointRepository)
  .to(StatisticStaffPointService);

container
  .bind<IStatisticRepository>(TYPES.StatisticRepository)
  .to(StatisticService);

container
  .bind<IStatisticDepartmentRepository>(TYPES.StatisticDepartmentRepository)
  .to(StatisticDepartmentService);

container
  .bind<IStatisticWeekPointRepository>(TYPES.StatisticWeekPointRepository)
  .to(StatisticWeekPointService);

//Card
container.bind<ICardService>(TYPES.CardService).to(CardService);
container.bind<ICardRepository>(TYPES.CardRepository).to(CardRepository);
container.bind<CardViewModel>(CardViewModel).toSelf();

//general
container.bind<IVerifyService>(TYPES.VerifyService).to(VerifyService);
container.bind<ISearchLabService>(TYPES.SearchLabService).to(SearchLabService);
container.bind<IPrefCityServices>(TYPES.PrefCityService).to(PrefCityServices);
container
  .bind<IPrefCityRepository>(TYPES.PrefCityRepository)
  .to(PrefCityRepository);
container
  .bind<ICheckExistInfoService>(TYPES.CheckExistInfoService)
  .to(CheckExistInfoService);

// labo
container.bind(TYPES.LaboService).to(LaboService);
container.bind(TYPES.LaboRepository).to(LaboRepository);
container.bind<LaboViewModel>(LaboViewModel).toSelf();
// branch
container.bind(TYPES.BranchService).to(BranchService);
container.bind(TYPES.BranchRepository).to(BranchRepository);
container.bind<BranchViewModel>(BranchViewModel).toSelf();
// department
container.bind(TYPES.DepartmentService).to(DepartmentService);
container.bind(TYPES.DepartmentRepository).to(DepartmentRepository);
container.bind<DepartmentViewModel>(DepartmentViewModel).toSelf();
// Reason
container.bind(TYPES.ReasonService).to(ReasonService);
container.bind(TYPES.ReasonRepository).to(ReasonRepository);
container.bind<ReasonViewModel>(ReasonViewModel).toSelf();
//Partner
container.bind(TYPES.PartnerService).to(PartnerService);
container.bind(TYPES.PartnerRepository).to(PartnerRepository);
container.bind<PartnerViewModel>(PartnerViewModel).toSelf();
//Material
container.bind(TYPES.MaterialService).to(MaterialService);
container.bind(TYPES.MaterialRepository).to(MaterialRepository);
container.bind<MaterialViewModel>(MaterialViewModel).toSelf();
// Variation
container.bind(TYPES.VariationService).to(VariationService);
container.bind(TYPES.VariationRepository).to(VariationsRepository);
container.bind<VariationsViewModel>(VariationsViewModel).toSelf();

//Implant
container.bind(TYPES.ImplantService).to(ImplantService);
container.bind(TYPES.ImplantRepository).to(ImplantRepository);
container.bind<ImplantViewModel>(ImplantViewModel).toSelf();

// setting process
container
  .bind<ISettingProcessRepository>(TYPES.SettingProcessRepository)
  .to(SettingProcessService);

// setting Icon
container
  .bind<ISettingIconRepository>(TYPES.SettingIconRepository)
  .to(SettingIconService);

// setting product
container
  .bind<ISettingProductRepository>(TYPES.SettingProductRepository)
  .to(SettingProductService);

// setting lab
container
  .bind<ISettingLabRepository>(TYPES.SettingLabRepository)
  .to(SettingLabService);

//prefcity model
container.bind<PrefCityViewModel>(PrefCityViewModel).toSelf();

//auth model
container.bind<IAuthService>(TYPES.AuthService).to(AuthService);
container.bind<IAuthRepository>(TYPES.AuthRepository).to(AuthRepository);
container.bind<AuthViewModel>(AuthViewModel).toSelf();
container.bind<TContextHook>(TYPES.ContextHook).toDynamicValue(() => useHook());

//Register Model
container.bind<IRegisterService>(TYPES.RegisterService).to(RegisterService);
container
  .bind<IRegisterRepository>(TYPES.RegisterRepository)
  .to(RegisterRepository);
container.bind<RegisterViewModel>(RegisterViewModel).toSelf();

//setting employee model
container
  .bind<ISettingEmployeeService>(TYPES.SettingEmployeeService)
  .to(SettingEmployeeService);
container
  .bind<ISettingEmployeeRepository>(TYPES.SettingEmployeeRepository)
  .to(SettingEmployeeRepository);
container.bind<SettingEmployeeViewModel>(SettingEmployeeViewModel).toSelf();
container.bind<SettingBranchViewModel>(SettingBranchViewModel).toSelf();
container.bind<SettingDepartmentViewModel>(SettingDepartmentViewModel).toSelf();

//setting permission
container
  .bind<ISettingPermissionService>(TYPES.SettingPermissionService)
  .to(SettingPermissionService);
container
  .bind<ISettingPermissionRepository>(TYPES.SettingPermissionRepository)
  .to(SettingPermissionRepository);

// order
container.bind<IOrderRepository>(TYPES.OrderRepository).to(OrderService);
container.bind<ITaskRepository>(TYPES.TaskRepository).to(TaskService);

container
  .bind<IOrderGroupRepository>(TYPES.OrderGroupRepository)
  .to(OrderGroupService);

export { container };
