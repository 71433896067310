

export const url: any  = [
  { filedName: "lab_setting", url: "/setting/labo", text: "技工所情報", type: "setting" },
  { filedName: "employee_setting", url: "/setting/employee", text: "従業員一覧", type: "setting" },
  { filedName: "process_setting", url: "/setting/process/common", text: "工程設定", type: "setting" },
  { filedName: "product_setting", url: "/setting/product", text: "技工物設定", type: "setting" },
  { filedName: "material_setting", url: "/setting/material", text: "材料管理", type: "setting" },
  { filedName: "icon_setting", url: "/setting/icon/product", text: "アイコン管理", type: "setting" },
  { filedName: "reason_setting", url: "/setting/reason", text: "再製理由管理", type: "setting" },
  { filedName: "partner_setting", url: "/setting/partner", text: "取引先医院一覧", type: "setting" },

  // ** Add by role
  { filedName: "payment_method", url: "/setting/paymentMethod", text: "支払方法", type: "setting" },
  { filedName: "Administrative_settings", url: "/setting/management", text: "管理用設定", type: "setting" },

  { filedName: "remake_rate_clinic", url: "/statistic/clinic", text: "医院別再製率", type: "statistic" },
  { filedName: "remake_rate_user", url: "/statistic/staff", text: "技工士別再製率", type: "statistic" },
  { filedName: "register_remake", url: "/order/remake", text: "再製理由登録", type: "statistic" },
  { filedName: "point_user", url: "/statistic/staffPoint", text: "個人別実績", type: "statistic" },
  { filedName: "point_department", url: "/statistic/department", text: "部署別実績", type: "statistic" },
  { filedName: "tasks_status", url: "/tasks/employee", text: "製作状況", type: "statistic" },
  { filedName: "tasks_timeline", url: "/statistic/employee", text: "タイムライン", type: "statistic" },
  { filedName: "tasks_actual_hours", url: "/statistic/employee/actualHours", text: "実業務時間", type: "statistic" },
  { filedName: "point_52_week", url: "/statistic/weekPoint", text: "52週ポイント", type: "statistic" },
  { filedName: "dup_tasks_adjust", url: "/tasks/duplicate", text: "業務重複調整", type: "statistic" },
  
]