import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";



@injectable()
export class ReasonService {
  private prefix: string;

  constructor() {
    this.prefix = "/reasons";
  }

  async fetchV2All(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/categories`, { params } );
    return response;
  }

  async fetchAll(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, { params } );
    return response;
  }

  async fetch(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, { params } );
    return response;
  }

  async create(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, data);
    return response;
  }

  async update( id:number, params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}`, params);
    return response;
  }

  async delete(id:number): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}`);
    return response;
  }

  async sort(ids:Object): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/sort-order`, ids);
    return response;
  }

}
