import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { AppDispatch, RootState } from '../store';
import React, { useEffect } from 'react';
const Msg = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const {msg} = useSelector((state: RootState) => state.global);  
  useEffect(() => {
    const setClearMsg = () => {      
      const timer = setTimeout(() => {
        if(msg.text){
          dispatch({type: 'global/setMsg', payload: {text: '', status: 1}});
        }        
      }, 5000);
      return () => clearTimeout(timer);      
    }
    setClearMsg();
  }, [msg, dispatch]);
  return (
    msg.text ? (
      <div className={`msg groupMsg ${msg.status == -1 ? 'error' : msg.status == 0 ? 'warning' : 'success'}`}>
        <div className="success">{msg.text}</div>
      </div>
    ) : <></>
  )
}
export default Msg;