import React from "react";
import { HookContext, TContextHook } from "@/context/hook";
import { TaskViewModel } from "@/modelviews/task";
import { container } from "@/di";
import Image from "next/image";
import { imgAssets } from "@/constants/img";

export const EmptyTask = () => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const taskVM = container.resolve<TaskViewModel>(TaskViewModel);
  taskVM.setHooks(hooks);

  return (
    <div className="rightside">
      <div className="blockBusiness">
        <div className="img">
          <Image src={imgAssets.icoExcla1} alt="" />
        </div>
        <h3>業務を登録して開始してください</h3>
        <div className="box">
          <h5>指示書のバーコードを読み込んで製作工程を選択</h5>
          <div className="dflex dflex_center">
            <div className="img">
              <Image src={imgAssets.icoBar} alt="" />
            </div>
            <div className="img">
              <Image src={imgAssets.icoArr} alt="" />
            </div>
            <div className="img">
              <Image src={imgAssets.icoProject} alt="" />
            </div>
          </div>
          <p>※指示書内容（技工物・患者名・セット日）の修正も可能です。</p>
        </div>
        <div className="box">
          <h5>指示書を読み込まずに工程を選択</h5>
          <div className="dflex dflex_center">
            <div className="img">
              <Image src={imgAssets.icoProject} alt="" />
            </div>
          </div>
          <p>※指示書を読み込まない業務の実施が可能です。</p>
          <p className="red">※技工作業以外も含む</p>
        </div>
      </div>
      <div className="blockBtn">
        <ul className="dflex">
          <li>
            <a className="btn btn-hasInstruc disabled" href="">
              所持している
              <br />
              指示書{" "}
            </a>
          </li>
          <li>
            <a className="btn btn-addInstruc disabled" href="">
              現在作業予定の
              <br />
              工程へ指示書追加
            </a>
          </li>
          <li>
            <a className="btn btn-addProcess disabled" href="">
              所持指示書へ
              <br />
              工程追加
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
