import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";


@injectable()
export class MaterialRepository {
  constructor(
    @inject(TYPES.MaterialService) private materialService: any
  ) {}

  async fetchMetal (params: any): Promise<IDataResponse<any>> {
    let res = await this.materialService.fetchMetal(params);
    return res;
  }

  async fetchCategories(params: any): Promise<IDataResponse<any>> {
    let res = await this.materialService.fetchCategories(params);
    return res;
  }

  async fetchAll(params: any): Promise<IDataResponse<any>> {
    let res = await this.materialService.fetchAll(params);
    return res;
  }

  async sortOrder(data: any): Promise<IDataResponse<any>> {
    let res = await this.materialService.sortOrder(data);
    return res;
  }

  async fetch(id: number, params: any): Promise<IDataResponse<any>> {
    let res = await this.materialService.fetch(id, params);
    return res;
  }

  async update(id: number, data: any): Promise<IDataResponse<any>> {
    let res = await this.materialService.update(id, data);
    return res;
  }

  async delete(id: number): Promise<IDataResponse<any>> {
    let res = await this.materialService.delete(id);
    return res;
  }

  async create(params: any): Promise<IDataResponse<any>> {
    let res = await this.materialService.create(params);
    return res;
  }
}
