import React, { MouseEventHandler } from "react";
import Image from "next/image";
import { Modal } from "@/components/Modal";
import { IModalCloseParams } from "@/models/Modal";
import { HookContext, TContextHook } from "@/context/hook";
import { container } from "@/di";
import { SettingEmployeeViewModel } from "@/modelviews/setting/employee";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setFromFiledCustomSubmit, setFromFiledCustom } from "@/store/register";
import jaJP from 'date-fns/locale/ja';
import dayjs from "dayjs";

interface IProps {
  imgAssets: any;
  handleModalClose: (
    modalCloseParams: IModalCloseParams
  ) => void;
  [key: string]: any;
}
export const ModalConfirmRetireEmployee = ({
  imgAssets,
  modal,
  handleModalClose,
  ...props
}: IProps) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const employeeViewModel = container.resolve<SettingEmployeeViewModel>(
    SettingEmployeeViewModel
  );
  employeeViewModel.setHooks(hooks);
  
  const [selectedDate, setSelectedDate] = hooks.useState(new Date());
  
  

  const modalId = modal.modalIds.confirmRetireEmployee;
  const modalList = hooks.useSelector((state: any) => state.global.modalList);
  const modalData = modalList.find((item: any) => item.modalId === modalId);
  const isShow = modalData?.isShow;
  const data = modalData?.data;
  const employeeId = hooks.router.query?.id || hooks.router?.asPath.replace("/setting/employee/edit/","");
  
  const { currentEmployee } = hooks.useSelector((state: any) => state.settingEmployee );
  
  const intDate = currentEmployee?.retired_at || false
  
  React.useEffect( () => setSelectedDate( intDate ? new Date(intDate) : new Date() ) , [intDate, hooks.router] )

    
  const handleRetireEmployee = async () => {

    //if(employeeId && isShow){      
      const res: any = await employeeViewModel.retireEmployee({retired_at: new Date(selectedDate).toLocaleString("en-US", {timeZone: "Asia/Tokyo"}) }, employeeId);

      if(res?.success)
      {
        hooks.dispatch( setFromFiledCustomSubmit({ value: 1, name: 'retired_flg' }))
        return hooks.router.push("/setting/employee")
      }
      
    //}
  };
  return (
    <Modal
      isDefaultShow={false}
      isDefaultShowOnRoute={'/setting/employee/edit/[id]'}
      modalId={modal.modalIds.confirmRetireEmployee}
      modalType={modal.modalTypes.confirmModal}
      modalSubType='modalAlert'
      modalTitle="Modal title"
    >
      <>
        <div className="modal-body" id="dateRetirement">
          <div className="ico text_center"><Image src={imgAssets.icoExcla} alt="" /></div>
          <h4 className="text_center title f20">従業員の退職</h4>
          <p>従業員の退職は、これまで行った実績を指示書に紐づいた担当等残しておくことができます。ただし、ログインできなくなります。<br />従業員を退職としてもよろしいですか？</p>
          <div className="blockinput retirementdate dflex">
            <p>退職日</p>
            <DatePicker
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => {
              return (
                <>
                <button 
                onClick={decreaseMonth} 
                disabled={prevMonthButtonDisabled}
                type="button" 
                className="react-datepicker__navigation react-datepicker__navigation--previous" 
                aria-label="Previous Month">
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span>
                </button>

                <button
                onClick={increaseMonth} 
                disabled={nextMonthButtonDisabled}
                type="button" 
                className="react-datepicker__navigation react-datepicker__navigation--next" 
                aria-label="Next Month">
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span>
                </button>
              
                <div className="react-datepicker__header ">
                  <div className="react-datepicker__current-month"> {`${date.getFullYear()}年 ${date.getMonth() + 1}月`}</div>
                </div>
                </>
              )}}
              selected={selectedDate}
              onChange={(date: Date) => setSelectedDate(date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="yyyy/mm/dd"
              locale={jaJP}
            />
          </div>
        </div>
        <div className="modal-footer dflex">
          <button className="btn btn-outline-secondary minw140" type="button" onClick={(e) => {
              handleModalClose({                
                modalId: modal.modalIds.confirmRetireEmployee,
                isCloseAll: false,                
              });              
            }}>キャンセル</button>
          <button className="btn btn-danger minw140" type="button" onClick={handleRetireEmployee}>退職</button>
        </div>
      </>
    </Modal>
  );
};
