import React from "react";
import { IModalCloseParams } from "@/models/Modal";
import { ModalConfirmDeleteEmployee } from "@/components/modals/setting/modalConfirmDeleteEmployee";
import { ModalConfirmRetireEmployee } from "@/components/modals/setting/modalConfirmRetireEmployee";
import { ModalConfirmRecoverEmployee } from "@/components/modals/setting/modalConfirmRecoverEmployee";
import { HookContext, TContextHook } from "@/context/hook";
import { setModal } from "@/store/global";
interface IProps {
  imgAssets: any;
  [key: string]: any;
}
export const AllModals: React.FC<IProps> = ({ ...props }) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const handleModalClose = (modalCloseParams: IModalCloseParams) => {
    const modalId = modalCloseParams.modalId;
    const isCloseAll = modalCloseParams.isCloseAll;
    if(!isCloseAll){
      hooks.dispatch(setModal({
        modalId,
        isCloseAll,
        isShow: false
      }))
    }
  }
  return (
    <>
      <ModalConfirmDeleteEmployee
        handleModalClose={handleModalClose}
        {...props}
      />
      <ModalConfirmRetireEmployee
        handleModalClose={handleModalClose}
        {...props}
      />
      <ModalConfirmRecoverEmployee
        handleModalClose={handleModalClose}
        {...props}
      />
    </>
  );
};
