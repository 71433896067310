
import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";
import { IRegisterParams } from "@models/Register";

export interface IVerifyParams {
  code: string;
  type: string;
}

export interface IVerifyService {
  verifyCode(params: IVerifyParams): Promise<IDataResponse<any>>;  
}

@injectable()
export class VerifyService implements IVerifyService {
  private prefix: string;
  constructor() {
    this.prefix = '/verify';
  }
  async verifyCode(params: IVerifyParams): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, params);    
    return response;
  }
}