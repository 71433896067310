import { mappingStaffPoint, sort } from "@/helpers/stastistic";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  filter: any;
  staffPoint: any;
  staffPoints: any;
  sort: any;
  sortList: any;
  staffPointByType: any;
  tasks: any;
  staffPointModals: any;
  chartConfig: any;
  freeTime: any;
}

const initialState: IState = {
  tasks: {
    data: [],
    currentPage: null,
    total: 0,
    lastPage: null,
    itemPerPage: 10,
    isStopLoading: false,
    limit: 20,
  },
  freeTime: null,
  staffPoint: null,
  staffPointByType: {
    currentMonth: null, // YYYY-MM
    currentDate: null, // YYYY-MM-DD
    day: {
      data: null,
      filter: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        day: new Date().getDate(),
      },
    },
    month: {
      data: null,
      filter: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        day: new Date().getDate(),
      },
    },
    year: {
      data: null,
      filter: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        day: new Date().getDate(),
      },
    },
    half_year: {
      data: null,
      filter: {
        from: null,
        to: null,
        year: new Date().getFullYear(),
      },
    },
  },
  staffPoints: {
    from: null,
    to: null,
    data: [],
    pagination: {
      currentPage: 1,
      total: 0,
      lastPage: null,
      itemPerPage: 10,
      isStopLoading: false,
      limit: 20,
    },
    isScrollLoading: false,
    isLoadingApi: false,
  },
  sort: {
    sort_type: "desc",
    sort_by: "totalPoint",
  },

  sortList: {
    totalPoint_desc: "ポイントが高い順",
    totalPoint_asc: "ポイントが低い順",
    avgProduct_desc: "1個平均の時間が早い順",
    avgProduct_asc: "1個平均の時間が遅い順",
    differencePoint_desc: "差分プラスが大きい順",
    differencePoint_asc: "差分マイナスが大きい順",
    avgDay_desc: "1日平均が高い順",
    avgDay_asc: "1日平均が低い順",
    avgTime_desc: "1時間平均が高い順",
    avgTime_asc: "1時間平均が低い順",
    rank_desc: "ランクが高い順",
    rank_asc: "ランクが低い順",
    totalTime_desc: "業務時間が長い順",
    totalTime_asc: "業務時間が短い順",
    workingDay_desc: "勤務日数が多い順",
    workingDay_asc: "勤務日数が少ない順",
    employeeBarcode_desc: "従業員番号が大きい順",
    employeeBarcode_asc: "従業員番号が小さい順",
  },

  filter: {
    viewMode: "compare", // compare, breakdown
    type: "month", // month, year, date, half_year
    month: null,
    year: null,
    day: null,
    from: null,
    to: null,
    branches: [],
    departments: [],
    users: [],
    roles: [],
    processCategory: null,
    process: null,
    sort: "totalPoint_desc",

    modals: {
      showSort: false,
      showRole: false,
      showBranch: false,
      showDepart: false,
      showProcess: false,
    },
  },

  staffPointModals: {
    chartDay: false,
    chartYear: false,
    chartMonth: false,
    chartHalfYear: false,
    task: false,
    info: false,
  },

  chartConfig: {
    viewType: "",
  },
};

export const statisticStaffPointSlice = createSlice({
  name: "statisticStaffPoint",
  initialState,
  reducers: {
    resetStaffPoints(state) {
      state.staffPoints = { ...initialState.staffPoints };
      state.staffPoints.isScrollLoading = false;
    },

    resetFilter(state) {
      state.filter = { ...initialState.filter };
      state.sortList = { ...initialState.sortList };
    },

    resetStaffPoint(state) {
      state.staffPoint = null;
    },

    resetTask(state) {
      state.tasks = { ...initialState.tasks };
    },

    updateSort(state, action) {
      state.sort = action.payload;
    },

    setIsLoadingStaffPoints(state, action) {
      state.staffPoints.isLoadingApi = action.payload;
    },

    fetchStaffPoints(state, action) {
      let staffPoints = state.staffPoints;
      const { from, to, statistic_data, pagination, process } = action.payload;

      let staffPointPagination = staffPoints.pagination;
      let employees = [];
      let staffPointData = staffPoints.data;

      // map data
      for (let i = 0; i < statistic_data.length; i++) {
        employees[i] = mappingStaffPoint(statistic_data[i], process);
      }

      if (pagination.last_page === 1) {
        staffPointData = [...employees];
      } else if (pagination.last_page >= pagination.current_page) {
        staffPointData = [...staffPointData, ...employees];
      }

      // map pagination
      staffPointPagination.currentPage = pagination.current_page;
      staffPointPagination.itemPerPage = pagination.per_page;
      staffPointPagination.total = pagination.total;
      staffPointPagination.lastPage = pagination.last_page;

      // check stop load api
      if (staffPointPagination.currentPage === pagination.last_page) {
        staffPointPagination.isStopLoading = true;
      } else if (staffPointPagination.currentPage < pagination.last_page) {
        staffPointPagination.isStopLoading = false;
      }

      staffPoints = {
        from: from,
        to: to,
        data: staffPointData,
        pagination: staffPointPagination,
      };

      state.staffPoints = { ...staffPoints };

      // handle sort
      // let { sort_by, sort_type } = state.sort;
      // let sortEmployees = sort(sort_by, sort_type, employees)
    },

    fetchStaffPoint(state, action) {
      const { from, to, statistic_data, time_data } = action.payload;
      let data = mappingStaffPoint(statistic_data);

      state.staffPoint = {
        from: from,
        to: to,
        statisticData: data,
        timeData: time_data,
      };
    },

    fetchTasks(state, action) {
      let tasks = { ...state.tasks };
      let data = action.payload;
      if (data.last_page === 1) {
        tasks.data = [...data.data];
      } else if (data.last_page >= data.current_page) {
        tasks.data = [...tasks.data, ...data.data];
      }

      tasks.currentPage = data.current_page;
      tasks.itemPerPage = data.per_page;
      tasks.total = data.total;
      tasks.lastPage = data.last_page;
      if (tasks.currentPage === data.last_page) {
        tasks.isStopLoading = true;
      } else if (tasks.currentPage < data.last_page) {
        tasks.isStopLoading = false;
      }
      state.tasks = tasks;
    },

    fetchFreeTime(state, action) {
      let data = action.payload;
      state.freeTime = data;
    },

    updateStaffPoint(state, action) {
      const { key, val } = action.payload;
      let staffPoint = { ...state.staffPoint };
      staffPoint[key] = val;
      state.staffPoint = staffPoint;
    },

    fetchStaffPointByType(state, action) {
      let staffPointByType = state.staffPointByType;
      let { type, data, filter } = action.payload;

      staffPointByType[type] = {
        data: data,
        filter: { ...staffPointByType[type].filter, ...filter },
      };
      state.staffPointByType = staffPointByType;
    },

    setCurrentMonth(state, action) {
      state.staffPointByType.currentMonth = action.payload;
    },

    setCurrentDate(state, action) {
      state.staffPointByType.currentDate = action.payload;
    },

    updateStaffPoints(state, action) {
      const { from, to, data } = action.payload;
      state.staffPoints = {
        from: from,
        to: to,
        data: data,
      };
    },

    updateChartConfig(state, action) {
      let { key, val } = action.payload;
      let chartConfig = { ...state.chartConfig };
      chartConfig[key] = val;
      state.chartConfig = chartConfig;
    },

    updateFilter(state, action) {
      let filter = { ...state.filter };
      let data = action.payload;

      for (let prop in data) {
        filter[prop] = data[prop];
      }
      state.filter = filter;
    },

    setFilter(state, action) {
      let { key, val } = action.payload;
      let filter = { ...state.filter };
      // handle modals
      if (filter.modals.hasOwnProperty(key)) {
        for (let prop in filter.modals) {
          filter.modals[prop] = false;
        }
        filter.modals[key] = val;
      } else {
        // handle prop
        filter[key] = val;
      }

      state.filter = filter;
    },

    setIsScrollLoading(state, action) {
      state.staffPoints.isScrollLoading = action.payload;
    },

    showStaffPointModal(state, action) {
      let data = action.payload;
      let staffPointModals = state.staffPointModals;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in staffPointModals) {
          staffPointModals[property] = false;
        }
      }
      staffPointModals[key] = val;
      state.staffPointModals = staffPointModals;
    },

    resetModal(state) {
      let initFilter = { ...initialState.filter };
      state.filter.modals = { ...initFilter.modals };
    },
  },
});

export default statisticStaffPointSlice;
export const orderGroupReducer = statisticStaffPointSlice.reducer;
export const statisticStaffPointSliceAction = statisticStaffPointSlice.actions;
