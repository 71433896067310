import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";


@injectable()
export class LaboRepository {
  constructor(
    @inject(TYPES.LaboService) private laboService: any,
  ) {}
  async updateLabs(params: any,  id: number ): Promise<IDataResponse<any>> {
    let res = await this.laboService.updateLabs(params, id);
    return res;
  }

}
