import { ICardForm } from "@/models/card/Card";

type FieldNameType = {
  [key: string]: any;
};
export const fieldName: FieldNameType = {
  name_mei: "名前",
  name_sei: "苗字",
  email: "メールアドレス",
  password: "パスワード",
  passwordConfirm: "パスワード確認",
  keyword: "キーワード",
  lab_name: "技工所名",
  lab_name_f: "技工所名ふりがな",
  lab_phone: "電話番号",
  lab_zip: "郵便番号",
  pref_id: "都道府県",
  city_id: "市区町村",
  lab_address: "住所",
  card: {
    card_number: "カード番号",
    card_month: "有効期限",
    card_year: "有効期限",
    card_cvc: "セキュリティコード",
  },

  name: "部署名",
  material_name: "材料名",
  reason_name: "再製理由",
  branch_ids: "拠点選択",

  // partner
  clinic_corp: "法人名",
  clinic_name: "歯科医院名",
  clinic_name_f: "ふりがな",
  clinic_phone: "電話番号",
  clinic_zip: "郵便番号",
  clinic_address: "町名・番地",
  clinic_building: "建物名",
  is_ios: "IOS利用医院",

  employee_barcode: '従業員番号',
  branch_id: '拠点',
  department_ids: '部署',
  user_role: '権限',
  target_point: '1日の目標ポイント',
  lab_fax: 'FAX'
};
