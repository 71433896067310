import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IAuthToken, IUserCredentials } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import {  setInfo  } from '@/store/auth/profile'
import { setLoading, setMsg } from "@/store/global";
import { parseUtf8 } from "@/helpers/encryption";
import { msg } from "@msg";
import { setLabo } from "@/store/setting";
import { deleteCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { setFormFieldSubmit, setSelectedLaboToSubmit } from "@/store/register";
import { BaseViewLabo } from "./BaseViewLabo";
import { message } from "@/constants/message";

@injectable()
export class LaboViewModel extends BaseViewLabo {

  constructor(
    @inject(TYPES.LaboRepository) private LaboRepository: any,

    @inject(TYPES.LaboService) private profile: any,
  )
  {
    super();
  }

  async setProfile( profile: object ){
      return this.profile = profile;
  }

  async updateLabs( param : any ): Promise<any>
  {
    let { profile }: any = this.hooks.getState();
    const { Lab: { id } }: any = profile
    let res : any = await this.withLoading( () =>  this.LaboRepository.updateLabs( param, id )  );
    
    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    
    // profile = { ...profile,  Lab: res.data }
    
    this.setMsg(res.message || message.success, 1);
    return { ...profile,  Lab: res.data }
  }

  async getlab( ){

    const { Lab }: any = this.hooks.getState().profile;
    
    const keyInclude: any = [ 'lab_corp_kbn', 'lab_name', 'lab_name_f', 'lab_phone', 'lab_fax', 'lab_zip', 'pref_id', 'city_id', 'lab_address', 'lab_building' ]
    const keys: any = Object.keys(Lab).filter( (val:any) => keyInclude.includes(val as string) )
    
    keys.forEach( (key: string) => {
      if(  Lab[key] as string )
        this.hooks.dispatch( setFormFieldSubmit( { value: Lab[key].toString() , name: key.toString() } ) );
    });

    return true;
  }

}
