import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";
import { IZipToPrefCity } from "@/models/Prefcity";
import { normalizeZipToPrefCity } from "@services/nomalizeModel/normalizeZipToPrefCity";
import { ICity, IPref } from "@/models/Lab";

export interface IPrefCityServices {
  zipToPrefCity(params: any): Promise<IDataResponse<IZipToPrefCity>>;
  getListPref(): Promise<IDataResponse<IPref[]>>;
  getCityByPref(pref_id: number): Promise<IDataResponse<ICity[]>>;
}

@injectable()
export class PrefCityServices implements IPrefCityServices {
  private prefix: string;
  constructor() {
    this.prefix = "/labs";
  }
  async zipToPrefCity(params: any): Promise<IDataResponse<IZipToPrefCity>> {
    const response = await axiosService.get(`/zipcode`, { params });
    if (response.data)      
      response.data = normalizeZipToPrefCity(response.data);
    return response;
  }
  async getListPref(): Promise<IDataResponse<IPref[]>> {
    const response = await axiosService.get(`/get-prefs`);
    if (response.data)
      response.data = response.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          region_id: item.region_id,
        };
      });
    return response;
  }
  async getCityByPref(params: any): Promise<IDataResponse<ICity[]>> {
    const response = await axiosService.get(`/get-cities`, { params });
    if (response.data)
      response.data = response.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          pref_id: item.pref_id,
        };
      });
    return response;
  }
}
