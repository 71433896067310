import React, { useEffect, useState } from 'react';
import { imgAssets } from '@/constants/img';
import Image from "next/image";
import { ITask, taskStatusEnum } from '@/models/Task';
import Link from 'next/link';

interface PauseTimeProp {
  task: ITask,
  closeModal: () => void
  doneTask: () => void
}

export const DoneTask = (props: PauseTimeProp) => {  
  return (
    props.task.status == taskStatusEnum.confirmed ?
    <div className="modal fade show modalAlert" id="confirmModal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="ico text_center">
            <Image unoptimized={true} src={imgAssets.icoExcla} alt="" />
            </div>
            <h4 className="text_center title f20">工程終了確認</h4>
            <p>時間計測されていません。このまま終了するとポイントは付与されません。<br/>工程を終了してよろしいですか。<br/>（管理者に確認してください）</p>
          </div>
          <div className="modal-footer dflex">
            <button className="btn btn-outline-secondary minw140" type="button"  onClick={() => { props.closeModal() }}>キャンセル</button>
            <button className="btn btn-danger minw140" type="button"  onClick={() => props.doneTask()}>工程を終了</button>
          </div>
        </div>
      </div>
    </div>
    :
    (
      props.task.ProcessOrder?.length > 0 ? 
      <div className="modal fade show modalAlert" id="completionModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="ico text_center">
                <Image unoptimized={true} src={imgAssets.iconCheck3} alt="" />
              </div>
              <h4 className="text_center title f20">工程終了確認</h4>
              <p>下記の工程に読み込んだ指示書は全て実施済ですか。</p>
              <ul className="bridge">
                <li>［{ props.task.Task?.ProcessCategory?.name }］{ props.task.Task?.name }</li>
              </ul>
            </div>
            <div className="modal-footer dflex">
              <button className="btn btn-outline-secondary minw140" type="button" onClick={() => { props.closeModal() }}>キャンセル</button>
              <Link className="btn btn-outline-success minw140" type="button" href={`/tasks/chosseOrder?id=${props.task.id}`}>選択して終了</Link>
              <button className="btn btn-success minw140" type="button" onClick={() => props.doneTask()}>すべて終了</button>
            </div>
          </div>
        </div>
      </div> 
      :
      <div className="modal fade show modalAlert" id="completionModal">
        <div className="modal-dialog modal-dialog-small modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="ico text_center">
              <Image unoptimized={true} src={imgAssets.iconCheck3} alt="" />
              </div>
              <h4 className="text_center title f20">工程終了確認</h4>
              <p>下記の工程を終了します。よろしいですか。</p>
              <ul className="bridge">
                <li>［{ props.task.Task?.ProcessCategory?.name }］{ props.task.Task?.name }</li>
              </ul>
            </div>
            <div className="modal-footer dflex">
              <button className="btn btn-outline-secondary minw140" type="button"  onClick={() => { props.closeModal() }}>キャンセル</button>
              <button className="btn btn-success minw140" type="button"  onClick={() => props.doneTask()}>工程終了</button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
