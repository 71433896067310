import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface ISettingIconService {
  fetchAllIcons(params: any): Promise<IDataResponse<any>>;
  fetchIcon(id: number, params: any): Promise<IDataResponse<any>>;
  createIcon(data: any): Promise<IDataResponse<any>>;
  updateIcon(id: number, data: any): Promise<IDataResponse<any>>;
  deleteIcon(id: number): Promise<IDataResponse<any>>;

  fetchIconCategories(params: any): Promise<IDataResponse<any>>;
}

@injectable()
export class SettingIconService implements ISettingIconService {
  private prefix: string;
  private prefixCategory: string;

  constructor() {
    this.prefix = "/labs/icons";
    this.prefixCategory = "/labs/icon-categories";
  }

  async fetchAllIcons(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }

  async fetchIcon(id: number, params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/${id}`, {
      params: params,
    });
    return response;
  }

  async updateIcon(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}`, data);
    return response;
  }

  async deleteIcon(id: number): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}`);
    return response;
  }

  async createIcon(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, data);
    return response;
  }

  async fetchIconCategories(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefixCategory}`, {
      params: params,
    });
    return response;
  }
}
