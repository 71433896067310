import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

interface IState {
  orderProcesses: Array<any>;
  noOrderProcesses: Array<number>;
  confirmOrderProcesses: Array<any>;
}

const initialState: IState = {
  orderProcesses: [],
  noOrderProcesses: [],
  confirmOrderProcesses: [],
};

export const orderProcessSlice = createSlice({
  name: "orderProcess",
  initialState,
  reducers: {
    reset(state) {
      state.orderProcesses = [...initialState.orderProcesses];
      state.noOrderProcesses = [...initialState.noOrderProcesses];
      state.confirmOrderProcesses = [...initialState.confirmOrderProcesses];
    },


    setConfirmOrderProcesses(state, action) {
      let dataMap: Array<any> = [];
      let orderProcesses = [...state.orderProcesses];

      orderProcesses.forEach((data: any) => {
        let obj: any = {};
        const { process, orders } = data;
        const processProducts = process.Products.map((item: any) => item);
        obj.process = process;

        let products: any = [];
        orders.forEach((order: any) => {
          order.orderProducts.forEach((orderProduct: any) => {
         
            let findProcessProduct = processProducts.find(
              (product: any) => product.id === orderProduct.id
            );
            // handle case product switch
            if(orderProduct.breakdownFlg)
            {
              let selectedProductSwitches = [...orderProduct.selectedSwitchProducts]
              selectedProductSwitches.forEach((productSwitch: any) => {
                let findProcessProduct = processProducts.find(
                  (product: any) => product.id === productSwitch.DesProduct.id
                );

                if (findProcessProduct) {
                  let findProductIndex =  products.findIndex(
                    (product: any) => product.id === productSwitch.DesProduct.id
                  );
  
                  if(findProductIndex !== -1)
                  {
                    let getProduct = { ...products[findProductIndex] };
                    getProduct.quantity += productSwitch.quantity;
                    getProduct.orderIds.push(order.id);
                    products[findProductIndex] = getProduct;
                  }else {
                   
  
                    products.push({
                      id: productSwitch.DesProduct.id,
                      name: productSwitch.DesProduct.name,
                      quantity: productSwitch.quantity,
                      processTime: findProcessProduct.process_time,
                      processPoint: findProcessProduct.process_point,
                      orderIds: [order.id],
                    });
                  }
                }
              })
            }else if (findProcessProduct) {
              // check product exist
              let findProductIndex = products.findIndex(
                (product: any) => product.id === orderProduct.id
              );
              if (findProductIndex !== -1) {
                let getProduct = { ...products[findProductIndex] };
                getProduct.quantity += orderProduct.quantity;
                getProduct.orderIds.push(order.id);
                products[findProductIndex] = getProduct;
              } else {
                products.push({
                  id: orderProduct.id,
                  name: orderProduct.name,
                  quantity: orderProduct.quantity,
                  processTime: findProcessProduct.process_time,
                  processPoint: findProcessProduct.process_point,
                  orderIds: [order.id],
                });
              }
            }
          });
        });
        obj.products = products;
        dataMap.push(obj);
      });
      state.confirmOrderProcesses = dataMap;
    },

    addProcess(state, action) {
      const { orders, process } = action.payload;
      let orderProcesses = [...state.orderProcesses];
      let findProcessIndex = orderProcesses.findIndex(
        (item: any) => item.process.id === process.id
      );

      if (findProcessIndex !== -1) {
        // process.orders = orders;

        // if (process.orders.length > 0) {
        //   orderProcesses[findProcessIndex] = process;
        // } else {
        //   orderProcesses.splice(findProcessIndex, 1);
        // }
        orderProcesses.splice(findProcessIndex, 1);
      } else {
        orderProcesses.push({
          process: process,
          orders: orders,
        });
      }

      state.orderProcesses = orderProcesses;
    },
    addNoOrderProcesses(state, action) {
      const { process } = action.payload;
      let noOrderProcesses = [...state.noOrderProcesses];
      let findProcessIndex = noOrderProcesses.findIndex(
        (item: any) => item.id === process.id
      );

      if (findProcessIndex !== -1) {
        noOrderProcesses.splice(findProcessIndex, 1);
      } else {
        noOrderProcesses.push(process);
      }
      state.noOrderProcesses = noOrderProcesses;
    },
  },
  extraReducers: (builder) => {},
});

export default orderProcessSlice;
export const orderReducer = orderProcessSlice.reducer;
export const orderProcessSliceAction = orderProcessSlice.actions;
