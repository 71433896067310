import { IAuthToken, IUserCredentials, IUserQRCredentials, IUserUpdate } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import { setInfo } from "@/store/auth/profile";
import { setLabo } from "@/store/setting";
import { setLoading, setMsg } from "@/store/global";
import { msg } from "@msg";

import type { IAuthRepository } from "@repositories/interface/IAuthRepository";
import { BaseViewModel } from "@/modelviews/BaseViewModel";

import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";

import { deleteCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { setFormField, setVerifyCode } from "@/store/register";
import { parseUtf8 } from "@/helpers/encryption";

@injectable()
export class AuthViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.AuthRepository) private authRepository: IAuthRepository
  ) {
    super();
  }

  setCookie(token: IAuthToken["access_token"]) {
    setCookie("access_token", token, {
      expires: new Date(dayjs().add(20, "day").format("YYYY-MM-DD HH:mm:ss")),
    });
  }

  deleteCookie() {
    deleteCookie("access_token");
  }

  async logOut(): Promise<any> {
    return await this.authRepository.logOut();
  }

  async login(useCredentials: IUserCredentials): Promise<any> {
    return await  this.withLoading( () => this.authRepository.login(useCredentials) );
  }

  async loginByQR(data: IUserQRCredentials): Promise<any> {
    return  await this.withLoading( () => this.authRepository.loginByQR(data) );
  }

  async getProfile(isServerSide?: boolean): Promise<any> {
    let res = await this.authRepository.getProfile();
    if (res.success && res.data) {      
      const data:any = res.data
      
      let Permission:any = data?.UserPermissionOption.map( (val:any) => ({ permission: val?.Permission?.value, option: val?.Option?.value }) ) 
      
      if( ['lab_super_admin', 'lab_admin'].includes( data?.user_role as string ) )
        Permission = [ ...Permission, ... [{ permission: 'payment_method', option: 'all' }, { permission: 'Administrative_settings', option: 'all' } ] ]
      
      this.hooks.dispatch(setInfo({ ...data, Permission  }));

      // ** asset cokie
      setCookie( 'ctp5', parseUtf8({ userId: data?.id, user_role: data?.user_role, Permission } ) )
      
      // setCookie

      this.hooks.dispatch(loginSuccess({}));
    } else {
      this.hooks.dispatch(loginFail({}));
      this.deleteCookie();
    }
    return res;
  }

  async updateProfile(data: IUserUpdate) : Promise<any> {
    let res = await this.authRepository.updateProfile(data);
    if (res.success && res.data) {
      this.hooks.dispatch(setInfo({ ...res.data }));
    }else {
      this.setMsg(res.message, -1);
    }
    return res;
  }
  async forgotPassword(params: any): Promise<any> {
    return await this.withLoading(() => this.authRepository.forgotPassword(params) );
  }

  async resetPassword(params: any): Promise<any> {
    return await this.withLoading(() => this.authRepository.resetPassword(params) );
  }

}
