
import axiosService from "@services/axiosService";
import { IUserCredentials, IAuthToken, Profile } from "@models/auth";
import { IAuthService } from "@services/interface/IAuthService";
import { IDataResponse } from "@services/interface/IDataResponse";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { injectable } from "inversify";


// interface DepartmentInterFace
// {
//   getDepart( laboid: number ): Promise<IDataResponse<any>>,
//   updateDepart(params: any, laboid: number, deptid: number ): Promise<IDataResponse<any>>,
//   createDepart( params: any, laboid : number ) : Promise<IDataResponse<any>>,
//   deleteDepart( laboid: number, deptid: number ): Promise<IDataResponse<any>>
// }

@injectable()

export class DepartmentService {

  private prefix: string;

  constructor() {
    this.prefix = '/labs';
  }

  async fetchs( params : any )  {
    return await axiosService.get(`${this.prefix}/departments`, { params });
  }

  async getDepart()  {
    const response = await axiosService.get(`${this.prefix}/departments`);
    return response;
  }

  async updateDepart(params: any, laboid: number, deptid: number ): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/departments/${deptid}`, params);
    return response;
  }

  async createDepart( params: any, laboid : number ) {
    const response = await axiosService.post(`${this.prefix}/departments`, params);
    return response;
  }

  async deleteDepart( laboid: number, deptid: number ): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/departments/${deptid}`);
    return response;
  }

  async sort( ids: Object ): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/departments/sort-order`, ids );
    return response;
  }

}