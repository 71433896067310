import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";

import { BaseViewModel } from "@/modelviews/BaseViewModel";
import { type IRegisterRepository } from "@/datasource/repositories/register";
import { IRegisterParams } from "@/models/Register";
import { IVerifyParams } from "@/datasource/services/verifyService";

import { msg } from "@msg";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { setVerifyCode } from "@/store/register";
import { IListLab, ISearchLabParams } from "@services/searchLabService";
import { ICheckExistInfoParams } from "@/datasource/services/checkExistInfo";
import { IRegisterSubmitParams } from "@/datasource/services/register";
import { AuthViewModel } from "@/modelviews/auth";
import { container } from "@/di";
import { loginSuccess } from "@/store/auth";

@injectable()
export class RegisterViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.RegisterRepository) private registerRepository: IRegisterRepository
  ) {
    super();
  }
  async phoneInfo(params: any): Promise<IDataResponse<any>> {
    return await this.registerRepository.phoneInfo(params);
  }
  async register(params: IRegisterParams, isResent: boolean): Promise<IDataResponse<any>> {    
    const res = await this.withLoading(() => this.registerRepository.register(params));
    if (res.success) {      
      if(isResent){        
        this.setMsg(msg.register.sentEmailVerify, 1)
      }else{
        this.hooks.router.push("/register/emailVerify");
      }      
    } else {
      this.setMsg(res.message || '登録中にエラーが発生しました', -1);
    }
    return res;
  }

  async verifyCode(params: IVerifyParams): Promise<IDataResponse<any>> {    
    const res = await this.withLoading(() => this.registerRepository.verifyCode(params));
    this.hooks.dispatch(setVerifyCode({
      data: res.data,
      success: res.success,
      message: res.message
    }));
    return res;
  }

  async searchLab(params: ISearchLabParams): Promise<IDataResponse<IListLab>> {
    const res = await this.withLoading(() => this.registerRepository.searchLab(params));    
    return res;
  }

  async checkExist(params: ICheckExistInfoParams): Promise<IDataResponse<any>> {
    const res = await this.withLoading(() => this.registerRepository.checkExist(params));        
    if(res.success){
      this.hooks.router.push("/register/confirm");
    }else{
      this.setMsg(res.message || '電話番号はすでに存在しています。', -1);
    }
    return res;
  }

  async registerSubmit(params: IRegisterSubmitParams): Promise<IDataResponse<any>> {
    const res = await this.withLoading(() => this.registerRepository.registerSubmit(params));
    if (res.success) {
      const authViewModel = container.resolve<AuthViewModel>(AuthViewModel);
      authViewModel.setCookie(res.data.access_token);
      this.hooks.dispatch(loginSuccess({}));
      this.hooks.router.push("/register/card");      
    }else {
      this.setMsg(res.message || '登録中にエラーが発生しました', -1);
    }
    return res;    
  }
}
