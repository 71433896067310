
import axiosService from "@services/axiosService";
import { IUserCredentials, IAuthToken, Profile } from "@models/auth";
import { IAuthService } from "@services/interface/IAuthService";
import { IDataResponse } from "@services/interface/IDataResponse";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";
import { IDepartmentEmployee } from "@/models/setting/Department";
import { normalizeDepartmentEmployee } from "@services/nomalizeModel/normalizeDepartmentEmployee";


@injectable()
export class StatisticStaffRepository {


  constructor(
    @inject(TYPES.StatisticStaffService)
    private staffService: any
  ) {}

  async fetchAll( params :any): Promise<IDataResponse<any>> {
    return await this.staffService.fetchAll(params);
  }

  async fetchOne( id: any, params :any): Promise<IDataResponse<any>> {
    return await this.staffService.fetchOne(id, params);
  }

  async fetchStaffs( params :any): Promise<IDataResponse<any>> {
    return await this.staffService.fetchStaffs(params);
  }

}