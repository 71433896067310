import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import { useCategories } from "@/components/mixins/order/process/useCategories";
import { container } from "@/di";
import { OrderViewModel } from "@/modelviews/order";
import { orderProcessSliceAction } from "@/store/order/process";
import _ from "lodash"

export const useOrderProcess = () => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const orderVM = container.resolve<OrderViewModel>(OrderViewModel);
  orderVM.setHooks(hooks);

  const profile = hooks.useSelector((state: any) => state.profile);
  const stateOrder = hooks.useSelector((state: any) => state.order);
  const stateOrderProcess = hooks.useSelector(
    (state: any) => state.orderProcess
  );
  // order process
  const orderProcesses = stateOrderProcess.orderProcesses;
  const noOrderProcesses = stateOrderProcess.noOrderProcesses;

  // order progress
  const orderProgressSelected = stateOrder.orderProgressSelected;
  const orderProgresses = stateOrder.orderProgresses;
  const scanOrder = orderProgresses.length ? true : false;
  const { pathname, query } = hooks.router;

  const getSelectedProcessIds = (orderId: any) => {
    // no order
    if (!orderId) {
      return noOrderProcesses.map((process: any) => process.id);
    } else {
      return orderProcesses
        .filter((process: any) => {
          return process.orders.map((order: any) => order.id).includes(orderId);
        })
        .map((item: any) => item.process.id);
    }
  };

  const selectedProcessIds = getSelectedProcessIds(
    orderProgressSelected ? orderProgressSelected.id : null
  );

  const defaultType = () => {
    if (!Boolean(scanOrder)) return null;

    if (profile?.user_role == "lab_staff") return "common";

    return "product";
  };
  const [type, setType] = React.useState(defaultType());
  const [tapActive, setTapActive] = React.useState("tap0");
  let productSwitches = orderProgresses.map((order: any) => order.orderProducts
                                        .filter((orderProduct: any) => orderProduct.breakdownFlg === true)
                                            .map((orderProduct: any) => orderProduct.productSwitches)
                                            .map((productSwitch: any) => productSwitch).flat())
                                            .flat()

  // flow from topage
  let orderIds =  orderProgresses.map((order: any) => order.id);;
  // if (query.additional) {
  //   orderIds = orderProgresses.map((order: any) => order.id);
  // }

  let productSwitchIds = productSwitches.map((productSwitch: any) => productSwitch.DesProduct.id)
  let normalProductIds = orderProgresses.map((order: any) => order.orderProducts
                                        .filter((orderProduct: any) => orderProduct.breakdownFlg === false)
                                        .map((product: any) => product.id))
                                        .flat()

  let mergeProductIds = _.uniq([...productSwitchIds, ...normalProductIds])
  const { categories, fetchs } = useCategories(mergeProductIds, scanOrder, type, orderIds);


  const chooseProcess = (process: any) => {
    if (orderProgresses.length <= 0) {
      hooks.dispatch(
        orderProcessSliceAction.addNoOrderProcesses({
          process: {
            ...process,
            categoryType: type,
          },
        })
      );
    } else {
      hooks.dispatch(
        orderProcessSliceAction.addProcess({
          process: {
            ...process,
            categoryType: type,
          },
          orders: orderProgresses,
        })
      );
    }
  };

  const onChangeType = React.useCallback(
    (event: any) => {
      setType(event.target.getAttribute("data-value")), setTapActive("tap0");
    },
    [type]
  );

  const CustomNav = React.forwardRef(({ children }: any, ref: any) => (
    <div className="listul_l">
      <ul ref={ref} style={{ color: "#518CED" }}>
        {" "}
        {children}
      </ul>
    </div>
  ));

  const CustomNavLink = React.forwardRef(
    ({ children, onClick, className }: any, ref: any) => {
      return (
        <li
          className={className.replace("active", "-active")}
          style={{ color: "#518CED" }}
          onClick={(e: any) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <div className="box">
            <span className="text">{children}</span>
          </div>
          <span className="line"></span>
        </li>
      );
    }
  );

  const tapPane = React.forwardRef(({ children, className }: any, ref: any) => {
    return (
      <div
        className={
          className.replace("show", "") +
          (!className.split(" ").includes("show") ? " isHide" : "")
        }
      >
        {children}
      </div>
    );
  });

  const handleOrderProcess = () => {
    hooks.dispatch(orderProcessSliceAction.setConfirmOrderProcesses({}));
    hooks.router.push({
      pathname: "/order/process/confirm",
      query: query
    });
  };

  return {
    tapActive,
    setTapActive,
    fetchCate: fetchs,
    type,
    onChangeType,
    tapPane,
    CustomNav,
    CustomNavLink,
    chooseProcess,
    categories,
    selectedProcessIds,
    handleOrderProcess,
  };
};
