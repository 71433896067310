import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";


@injectable()
export class ImplantRepository {
  constructor(
    @inject(TYPES.ImplantService) private implantService: any
  ) {}

  async fetchManuByProdcut( id: any, params: any): Promise<IDataResponse<any>> {
    let res = await this.implantService.fetchManuByProdcut(id, params);
    return res;
  }

  async fetchCategories(): Promise<IDataResponse<any>> {
    return await this.implantService.fetchCategories();
  }

  async fetchAll(params: any): Promise<IDataResponse<any>> {
    let res = await this.implantService.fetchAll(params);
    return res;
  }

  async sortOrder(data: any): Promise<IDataResponse<any>> {
    let res = await this.implantService.sortOrder(data);
    return res;
  }

  async fetch(id: number, params: any): Promise<IDataResponse<any>> {
    let res = await this.implantService.fetch(id, params);
    return res;
  }

  async update(id: number, data: any): Promise<IDataResponse<any>> {
    let res = await this.implantService.update(id, data);
    return res;
  }

  async delete(id: number): Promise<IDataResponse<any>> {
    let res = await this.implantService.delete(id);
    return res;
  }

  async create(params: any): Promise<IDataResponse<any>> {
    let res = await this.implantService.create(params);
    return res;
  }
}
