import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";

import { BaseViewModel } from "@/modelviews/BaseViewModel";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { setBranchList, setDepartmentEmployee } from "@/store/setting/branch";
import { AssetsContext } from "@/context/theme";
import React from "react";

@injectable()
export class SettingBranchViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.BranchRepository) private settingBranchRepo: any,
    @inject(TYPES.BranchRepository) private permission: any
  ) {
    super();
    const res = React.useContext(AssetsContext) as any;
    this.permission = res?.screenPermission   
  }
  async getBranchList(): Promise<IDataResponse<any>> {
    
    let res = await this.settingBranchRepo.getBranch({ limit: -1, includes: 'Department' });

    if (res.success && res.data) {
      this.hooks.dispatch(setBranchList(res.data));
    } else {
      this.setMsg(res?.message, -1);
    }
    return res;
  }
  async getDepartmentEmployee(params: any): Promise<IDataResponse<any>> {
    let res = await this.settingBranchRepo.getDepartmentEmployee(params);
    if (res.success && res.data) {
      this.hooks.dispatch(setDepartmentEmployee(res.data.data));
    } else {
      this.setMsg(res?.message, -1);
    }
    return res;
  }

}