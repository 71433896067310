import { GetServerSidePropsContext } from "next";
import axios from "axios";
import { IAuthToken } from "@models/auth";
import getConfig from "next/config";
import jwt from "jsonwebtoken";
import dayjs from "dayjs";
import { decodeUtf8, parseUtf8 } from "@/helpers/encrySSR";

// const AES_KEY = process.env.AES_KEY || "";
// const AES_IV = process.env.AES_IV || "";
// const routeNeedLoadProfile = ["/setting/labo"];

const verifyToken = async ( access_token: IAuthToken["access_token"], ctx: GetServerSidePropsContext ) => 
{
  const baseURL = getConfig().publicRuntimeConfig.API_URL;

  axios.defaults.timeout = getConfig()?.publicRuntimeConfig?.REQUEST_TIMEOUT || 30000;

  try {

    const decoded = jwt.decode(access_token as string);
    
    if (!decoded) 
      return { isAuthenticated: false };

    const { exp } = decoded as { exp: number };
    
    if (exp < Date.now() / 1000) 
      return { isAuthenticated: false };

    const { userId } = decoded as { userId: string; };

    if (!userId) 
      return { isAuthenticated: false };

    const headers = access_token ? { Authorization: `Bearer ${access_token}` } : {};
    
    const response = await axios.get(`${baseURL}/profile/me?includes=Lab&includes=Permission`, { headers, });
    
    if (response.status === 200) {
      
      let dataRes:any = response.data?.data 

      // let Permission:any = dataRes?.Permission.map( (val:any) => (val?.value) ) 

      let Permission:any = dataRes?.UserPermissionOption.map( (val:any) => ({ permission: val?.Permission?.value, option: val?.Option?.value }) ) 
      
      if( ['lab_super_admin', 'lab_admin'].includes( dataRes?.user_role as string ) )
        Permission = [ ...Permission, ... [{ permission: 'payment_method', option: 'all' }, { permission: 'Administrative_settings', option: 'all' } ] ]
        // Permission = [ ...Permission, "payment_method", "Administrative_settings" ]
      
      dataRes = { ...dataRes, Permission }      

      // const key = CryptoJS.enc.Utf8.parse(AES_KEY);
      // const iv = CryptoJS.enc.Utf8.parse(AES_IV);
      // const ciphertext = CryptoJS.AES.encrypt( JSON.stringify(dataRes), key, { iv: iv, } );


      return {
        isAuthenticated: true,
        userId,
        labId: response.data?.data?.lab_id,
        userInfo: dataRes,
        ciphertext: parseUtf8( { userId, user_role: response.data?.data?.user_role, Permission: dataRes?.Permission } ),
        isProfileRefresh: true,
      };
    } else {
      return {
        isAuthenticated: false,
      };
    }
  } catch (error) {
    console.log("error by fetch data in auth getServerSideProps", error);
    return {
      isAuthenticated: false,
      error
    };
  }
};

export const getServerSidePropsHOC = async (ctx: GetServerSidePropsContext) => {

  const access_token: IAuthToken["access_token"] = ctx.req.cookies.access_token || null;

  const { isAuthenticated, labId, ciphertext, userInfo } = await verifyToken( access_token, ctx );
    
  let pathname = ctx.req.url || "";
  const currentRoute = new URL(pathname, `http://${ctx.req.headers.host}`).pathname;
  
  if ( (currentRoute.indexOf("emailVerify.json") > -1) || 
      (currentRoute.indexOf("verifyComplete") > -1) || 
      (currentRoute.indexOf("forget") > -1) ) 
    return { props: { isAuthenticated } }

  if (currentRoute === "/login" || currentRoute.indexOf("login.json") > -1) {
    if (isAuthenticated) {
      return {
        redirect: {
          destination: `/`,
          permanent: false,
        },
        props: { isAuthenticated },
      };
    } else {
      return {
        props: { isAuthenticated },
      };
    }
  }

  if (!access_token || !isAuthenticated) {    
    
    let destination = ctx.req.headers.referer || ""
        destination = destination ? `/login?from=${destination}` : `/login`
    
    
    return {
      redirect: {
        destination,
        permanent: true,
        query: {
          from: "pathname",
        },
      },
      props: { isAuthenticated },
    };
  }

  if (ciphertext) {    
    //const expires = new Date(Date.now() + 30 * 60 * 1000).toUTCString();
    const expires = new Date(dayjs().add(20, "day").format("YYYY-MM-DD HH:mm:ss")).toUTCString()
    ctx.res.setHeader("set-cookie", [ `ctp5 = ${ciphertext}; path=/; expires=${expires}`, ]);
  }

  return {
    props: { isAuthenticated, labId, userInfo },
  };

};
