import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { BaseViewModel } from "../BaseViewModel";
import { message } from "@/constants/message";


@injectable()
export class StatisticStaffViewModel extends BaseViewModel {

  constructor(
    @inject(TYPES.StatisticStaffRepository) private statisticStaff: any,
  )
  {
    super();
  }

  async fetchStaffs( param : any ): Promise<any>
  {
    return await this.withLoading( () =>  this.statisticStaff.fetchStaffs( param )  );
  }

  async fetchAll( param : any ): Promise<any>
  {
    const res: any = await this.withLoading( () =>  this.statisticStaff.fetchAll( param )  );

    if( res?.message )
      this.setMsg(res.message || message.error, -1);

    return res
    
  }

  async fetchOne( id: any, param : any ): Promise<any>
  {
    const res: any = await this.withLoading( () =>  this.statisticStaff.fetchOne( id, param )  );

    if( res?.message )
      this.setMsg(res.message || message.error, -1);

    return res
  }

}
