import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import { container } from "@/di";
import { OrderViewModel } from "@/modelviews/order";

export const useCreateOrder = () => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const orderVM = container.resolve<OrderViewModel>(OrderViewModel);
  orderVM.setHooks(hooks);
  const stateOrder = hooks.useSelector((state: any) => state.order);

  const { query } = hooks.router;
  const init = () => {};
  
  return {
    init,
    stateOrder,
  };
};
