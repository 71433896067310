import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import React, { memo, useEffect } from 'react'
import { GetServerSideProps, NextPage } from 'next';
import { getServerSidePropsHOC } from '@/middleware/auth';
import { imgAssets } from '@/constants/img';
import { HookContext, TContextHook } from '@/context/hook';
import { useCreateOrder } from '@/components/order/create/useCreate';
import { HomeComponent } from "@/components/tasks/home/index";
import { io } from "socket.io-client";
import { HomeLayout } from '@/layout/home';
import getConfig from 'next/config';
import { container } from '@/di';
import { AuthViewModel } from '@/modelviews/auth';
import { ModalFavorite } from '@/components/orderProcess/modals/favorite';
import { useOrderProcess } from "@/components/orderProcess/useProcess";

type Props = {
  children: React.ReactNode;
  imgAssets: any;
  className?: string;
  [key: string]: any;
};
const Child = (props: Props) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const { init, stateOrder } = useCreateOrder();

  
  const modalFavorite = hooks.useSelector((state: any) => state.global.modalList).find((item: any) => item.modalId === "Selection")?.isShow;
  
  const { tapPane, CustomNav, CustomNavLink, categories, fetchCate } = useOrderProcess();

  return (
    <HomeComponent>
      {
        modalFavorite && 
        (
          <ModalFavorite
            CustomNav={CustomNav}
            tapPane={tapPane}
            CustomNavLink={CustomNavLink}
            onSubmit={ (e:any) => fetchCate() }
          { ...{...props, hooks } }
          />
        )
      } 
    </HomeComponent>
  );
};

const { publicRuntimeConfig } = getConfig();
const { SOCKET_URL } = publicRuntimeConfig;

export const socket = io(
  SOCKET_URL ?? "http://localhost:5000"
);


const Home = (props: Props) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const authViewModel = container.resolve<AuthViewModel>(AuthViewModel);
  authViewModel.setHooks(hooks);  

  useEffect(() => {
    
    // Phuong add event listen when redirect page to clear storge

    const clearStorge = () => {      
      localStorage.setItem( "dateStore", "" )
    }

    authViewModel.getProfile();
    
    if( typeof window == "object" )
      clearStorge()

  }, []);

  return (
    <HomeLayout {...props} hideHeader={ true }>
      <Child {...props} />
    </HomeLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {  
  return getServerSidePropsHOC(context);    
}

Home.displayName = "Home";
export default Home