import { Stream } from "stream";
import CryptoJS from "crypto-js";
import getConfig from "next/config";
import { getCookie } from "cookies-next";

// const AES_KEY = process.env.AES_KEY || "";
// const AES_IV = process.env.AES_IV || "";

const { publicRuntimeConfig: {AES_KEY, AES_IV} } = getConfig();

const key = CryptoJS.enc.Utf8.parse(AES_KEY);
const iv = CryptoJS.enc.Utf8.parse(AES_IV);

export const parseUtf8 = (data :any) => {
    return CryptoJS.AES.encrypt( JSON.stringify(data), key, { iv: iv } );
}

export const decodeUtf8 = (data :any) => {    
    const bytes = CryptoJS.AES.decrypt(data, key, {iv: iv,});
    return JSON.parse( bytes.toString(CryptoJS.enc.Utf8) );
}

export const getAuth = () => {    
    try
    {
        const ctp5:any = getCookie("ctp5")
        
        if(!ctp5)
            return { success: false , Permission: [] }

        const auth = decodeUtf8(ctp5)
        return { success: true, ...auth }
    }
    catch(e:any)
    {
        return { success: false, Permission: [], ...e }
    }
}

 
