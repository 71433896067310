import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

interface IState {
  modals: any;
  orderHistory: any;
  orderHistories: any;
  filter: any;
  sort: any;
}

const initialState: IState = {
  orderHistories: {
    data: [],
    currentPage: null,
    total: 0,
    lastPage: null,
    itemPerPage: 10,
    isStopLoading: false,
  },
  orderHistory: null,
  filter: {
    keyword: "",
    classes: [],
    labStatus: [], // delivered, not_delivery
    duplicateTask: [], // 1, 0
    instructionStatus: [],
    startDate: null,
    endDate: null,
    view: "", // list, image
  },
  sort: {
    sort_by: "",
    sort_type: "",
  },
  modals: {
    showScan: false,
    showTrialConfirm: false,
    showConfirmOrderDetail: false,
    showImage: false,

    // showInstructionOrderScan: false,
    // showOverdateConfirmScan: false,
    // showOverdateFinishScan: false,
  },
};

export const orderHistorySlice = createSlice({
  name: "orderHistory",
  initialState,
  reducers: {
    reset(state) {
      state.orderHistories = { ...initialState.orderHistories };
      state.orderHistory = null;
      state.modals = {
        ...initialState.modals,
      };
      state.filter = { ...initialState.filter };
    },
    
    resetOrderHistory(state) {
      state.orderHistory = null;
    },

    resetData(state) {
      state.orderHistories.data = [...initialState.orderHistories.data ];
    },

    updateSort(state, action) {
      state.sort = action.payload;
    },

    resetFilter(state) {
      state.filter = { ...initialState.filter };
    },

    fetchOrderHistories(state, action) {
      let orderHistories = state.orderHistories;
      let data = action.payload;
      if (data.current_page > orderHistories.currentPage) {
        orderHistories.data = [...orderHistories.data, ...data.data];
      } else {
        orderHistories.data = [...data.data];
      }

      orderHistories.currentPage = data.current_page;
      orderHistories.itemPerPage = data.per_page;
      orderHistories.total = data.total;
      orderHistories.lastPage = data.last_page;
      if (orderHistories.currentPage === data.last_page) {
        orderHistories.isStopLoading = true;
      } else {
        orderHistories.isStopLoading = false;
      }

      state.orderHistories = orderHistories;
    },

    /*
      @action: {index, data}
    */
    updateOrderHistories(state, action) {
      let { index, data } = action.payload;
      let orderHistories = state.orderHistories;
      let orderHistory = orderHistories.data[index];
      orderHistory = { ...orderHistory, ...data };
      orderHistories.data[index] = { ...orderHistory };
      state.orderHistories = orderHistories;
    },

    fetchOrderHistory(state, action) {
      state.orderHistory = action.payload;
    },

    updateOrderHistory(state, action) {
      const { key, val } = action.payload;
      let orderHistory = { ...state.orderHistory };
      orderHistory[key] = val;
      state.orderHistory = orderHistory;
    },

    setFilter(state, action) {
      let filter = { ...state.filter };
      let { key, value } = action.payload;
      filter[key] = value;
      state.filter = filter;
    },

    showModal(state, action) {
      let data = action.payload;
      let modals = state.modals;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in modals) {
          modals[property] = false;
        }
      }
      modals[key] = val;
      state.modals = modals;
    },
  },
  extraReducers: (builder) => {},
});

export default orderHistorySlice;
export const orderHistoryReducer = orderHistorySlice.reducer;
export const orderHistorySliceAction = orderHistorySlice.actions;
