import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

interface IState {
  selectedPrintOrder: Array<any>;
  modals: any;
}

const initialState: IState = {  
  
  selectedPrintOrder: [],
  modals: {
    showCompletePrint: false,
  },
};

export const orderPrintSlice = createSlice({
  name: "orderPrint",
  initialState,
  reducers: {
    reset(state) {
      state.selectedPrintOrder = []
    },

    setStagePrint(state, action) {  
      state.selectedPrintOrder = [ ...action.payload ]
    },

    setSelectedPrintOrder(state, action) {  
      let order = action.payload
      let selectedPrintOrder = [...state.selectedPrintOrder];
      
      let findIndex = selectedPrintOrder.findIndex((item:  any)  => item.id == order.id);

      if(findIndex !== -1) 
      {
        selectedPrintOrder.splice(findIndex, 1)
      }else {
        selectedPrintOrder.push(order);
      }

      state.selectedPrintOrder = selectedPrintOrder
    },

    
    setPrintOrderImage(state, action) {  
      state.selectedPrintOrder = [  ...action.payload ]
    },

    setSelectAllPrintOrders(state, action) {
      let orders = action.payload
      let selectedPrintOrder = [...state.selectedPrintOrder]
      selectedPrintOrder = [...orders]
      state.selectedPrintOrder = selectedPrintOrder
    },

    showModal(state, action) {
      let data = action.payload;
      let modals = state.modals;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in modals) {
          modals[property] = false;
        }
      }
      modals[key] = val;
      state.modals = modals;
    },
   
  },
  extraReducers: (builder) => {},
});

export default orderPrintSlice;
export const orderReducer = orderPrintSlice.reducer;
export const orderPrintSliceAction = orderPrintSlice.actions;
