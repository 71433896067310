import { IDepartment } from "@/models/setting/Department";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  departmentList: IDepartment[];
}

const initialState: IState = {
  departmentList: [],
};

export const settingDepartmentSlice = createSlice({
  name: "settingDepartment",
  initialState,
  reducers: {
    setDepartmentList(state, action: PayloadAction<IDepartment[]>) {
      state.departmentList = action.payload;
    }
  }
});

export const settingDepartmentReducer = settingDepartmentSlice.reducer;
export const { setDepartmentList } = settingDepartmentSlice.actions;