import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IAuthToken, IUserCredentials } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import { setInfo } from "@/store/auth/profile";
import { setLoading, setMsg } from "@/store/global";
import { msg } from "@msg";
import { setLabo } from "@/store/setting";
import { deleteCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { AuthViewModel } from "@/modelviews/auth";
import type { IAuthRepository } from "@repositories/interface/IAuthRepository";
import {
  setFormFieldSubmit,
  setSelectedLaboToSubmit,
  setFromFiledCustomSubmit,
} from "@/store/register";
import { BaseViewLabo } from "./BaseViewLabo";
import { setLoadData } from "@/store/setting";
import { message } from "@/constants/message";
import { AssetsContext } from "@/context/theme";
import React from "react";

@injectable()
export class DepartmentViewModel extends BaseViewLabo {
  constructor(
    @inject(TYPES.DepartmentRepository) private departmentRepository: any,
    @inject(TYPES.DepartmentRepository) private prefix: string,
    @inject(TYPES.DepartmentRepository) private permission: any,
  ) {
    super();
    this.prefix = "department";
    const res = React.useContext(AssetsContext) as any;
    this.permission = res?.screenPermission   
  }

  private async profile(): Promise<any> {
    await this.checkProfile();
    return this.hooks.getState().setting.labo;
  }
  /**
   * name
   */

  public async dispatchField(): Promise<any> {
    let { items } = this.hooks.getState().setting.department;


    if( !items.length )
    {
      items = localStorage.getItem('departments')
      items = items ? JSON.parse( items ) : [] 
    }
     
    const { id } = this.hooks.router.query;

    const { name, Branch } = items.find((val: any) => val.id == id) || { name: '', Branch: [] };
    const data: any = {
      name,
      branch_ids: Branch.map((value: any) => value.id.toString()),
    };

    const include = ["name", "branch_ids"];

    Object.keys(data)
      .filter((key) => include.includes(key as string) && data[key])
      .forEach((key) => {
        this.hooks.dispatch(
          setFromFiledCustomSubmit({ value: data[key], name: key.toString() })
        );
      });
  }

  public async fetchs(params: any, isLoading: boolean = true): Promise<any> {

    if(  ['branch', 'department', 'only_me' ].includes( this.permission?.option as string ) )
      params = { ...params, screen: this.permission?.permission }
  
    const res: any = await ( isLoading ? this.withLoading(() => this.departmentRepository.fetchs(params) ) : this.departmentRepository.fetchs(params) );

    if (res.success) {
      localStorage.setItem( 'departments', JSON.stringify( res.data ) )
      this.hooks.dispatch(
        setLoadData({
          data: [...res.data],
          sort: {
            sort_by: params?.sort_by || "",
            sort_type: params?.sort_type || "",
          },
          current_page: 1,
          last_page: 1,
          type: this.prefix,
        })
      );

      return res;
    }
  }

  public async fetchAll(): Promise<any> {
    
    let params:any = { limit: -1  }

    if(  ['branch', 'department', 'only_me' ].includes( this.permission?.option as string ) )
      params = { ...params, screen: this.permission?.permission }
    
    const res: any = await this.departmentRepository.fetchs(params)


    if (res.success) {
      this.hooks.dispatch(
        setLoadData({
          data: [...res.data],
          current_page: 1,
          last_page: 1,
          type: this.prefix,
        })
      );
      return res;
    }
  }

  public async getDepart(action: string): Promise<any> {
    const { id } = await this.profile();
    const { items } = this.hooks.getState().setting.department;

    if (!items.length) await this.fetchAll();

    if (action == "edit") return await this.dispatchField();

    return true;
  }

  public async updateDepart(param: any): Promise<any> {
    await this.checkProfile();

    const { labo } = this.hooks.getState().setting;
    const { id } = this.hooks.router.query;

    const res: any = await this.withLoading(() =>
      this.departmentRepository.updateDepart(param, labo.id, id)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    const { items, current_page, last_page } =
      this.hooks.getState().setting.department;

    await this.fetchAll();

    this.setMsg(res.message || message.success, 1);
    this.hooks.router.push("/setting/labo/department");
  }

  public async createDepart(param: any): Promise<any> {
    await this.checkProfile();
    const {
      labo: { id },
    } = this.hooks.getState().setting;
    const res: any = await this.withLoading(() =>
      this.departmentRepository.createDepart(param, id)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    await this.fetchAll();

    this.setMsg(res.message || message.success, 1);
    this.hooks.router.push("/setting/labo/department");
  }

  public async deleteDepart(): Promise<any> {
    //await this.checkProfile();
    const { labo } = this.hooks.getState().setting;
    const { id } = this.hooks.router.query;

    const res: any = await this.withLoading(() =>
      this.departmentRepository.deleteDepart(labo.id, id)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    await this.fetchAll();

    this.setMsg(res.message || message.success, 1);
    this.hooks.router.push("/setting/labo/department");
  }

  public async sort(ids: Object): Promise<any> {
    // await this.checkProfile();
    // const { labo } = this.hooks.getState().setting;
    // const { id } = this.hooks.router.query;

    return await this.withLoading(() =>
      this.departmentRepository.sort({ ids })
    );

    //await this.fetchAll();
    //this.setMsg(res.message || message.success , 1);
  }
}
