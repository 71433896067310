import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";
import { type IRegisterService } from "@/datasource/services/register";
import { IRegisterParams } from "@/models/Register";
import { IVerifyParams, type IVerifyService } from "@services/verifyService";
import { IListLab, ISearchLabParams, type ISearchLabService } from "@/datasource/services/searchLabService";
import { IZipToPrefCity } from "@/models/Prefcity";
import { ICity, IPref } from "@/models/Lab";
import { type IPrefCityServices } from "@services/prefcity/prefcityService";

export interface IPrefCityRepository {
  zipToPrefCity(params: any): Promise<IDataResponse<IZipToPrefCity>>;
  getListPref(): Promise<IDataResponse<IPref[]>>;
  getCityByPref(pref_id: number): Promise<IDataResponse<ICity[]>>;
}
@injectable()
export class PrefCityRepository implements IPrefCityRepository {
  constructor(
    @inject(TYPES.PrefCityService) private prefCityService: IPrefCityServices
  ) {}
  async zipToPrefCity(params: any): Promise<IDataResponse<IZipToPrefCity>> {
    const response = await this.prefCityService.zipToPrefCity(params);
    return response;
  }
  async getListPref(): Promise<IDataResponse<IPref[]>> {
    const response = await this.prefCityService.getListPref();
    return response;
  }
  async getCityByPref(params: any): Promise<IDataResponse<ICity[]>> {
    const response = await this.prefCityService.getCityByPref(params);
    return response;
  }
}
