import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";
import { IRegisterSubmitParams, type IRegisterService } from "@/datasource/services/register";
import { IRegisterParams } from "@/models/Register";
import { IVerifyParams, type IVerifyService } from "@services/verifyService";
import { IListLab, ISearchLabParams, type ISearchLabService } from "@/datasource/services/searchLabService";
import { ICheckExistInfoParams, type ICheckExistInfoService } from "@/datasource/services/checkExistInfo";

export interface IRegisterRepository {
  register(params: IRegisterParams): Promise<IDataResponse<any>>;
  verifyCode(params: IVerifyParams): Promise<IDataResponse<any>>;
  searchLab(params: ISearchLabParams): Promise<IDataResponse<IListLab>>;
  checkExist(params: ICheckExistInfoParams): Promise<IDataResponse<any>>;
  registerSubmit(params: IRegisterSubmitParams): Promise<IDataResponse<any>>;
  phoneInfo(params: IRegisterSubmitParams): Promise<IDataResponse<any>>;
}
@injectable()
export class RegisterRepository implements IRegisterRepository {
  constructor(
    @inject(TYPES.RegisterService) private registerService: IRegisterService,
    @inject(TYPES.VerifyService) private verifyService: IVerifyService,
    @inject(TYPES.SearchLabService) private searchLabService: ISearchLabService,
    @inject(TYPES.CheckExistInfoService) private checkExistInfoService: ICheckExistInfoService,
  ) {}

  async phoneInfo(params: any): Promise<IDataResponse<any>> {
    return await this.registerService.phoneInfo(params);
  }
  async register(params: IRegisterParams): Promise<IDataResponse<any>> {
    let res = await this.registerService.register(params);
    return res;
  }
  async registerSubmit(params: IRegisterSubmitParams): Promise<IDataResponse<any>> {
    let res = await this.registerService.registerSubmit(params);
    return res;
  }
  async verifyCode(params: IVerifyParams): Promise<IDataResponse<any>> {
    let res = await this.verifyService.verifyCode(params);
    return res;
  }
  async searchLab(params: ISearchLabParams): Promise<IDataResponse<IListLab>> {
    let res = await this.searchLabService.searchLab(params);    
    return res;
  }
  async checkExist(params: ICheckExistInfoParams): Promise<IDataResponse<any>> {
    let res = await this.checkExistInfoService.checkExist(params);            
    return res;
  }
}
