import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRegisterFormFields, IRegisterSubmitField } from "@models/Register";
import FormValidation from "@/utils/validate";
import { fieldName } from "@/constants/validationFieldName";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { ILabInfo } from "@/models/Lab";
import { number } from "yup";

interface orderOwner {
  [key: string]: any;
  orders: any;
  total: number | null;
  lastPage: number | null;
  currentPage: number | null;
  selects: any;
  action: 'add' | 'delete';
  reload: boolean
}

const initialState: orderOwner = {
  orders: [],
  total: null,
  lastPage: null,
  currentPage: null,
  selects: [],
  action: 'add',
  reload: false
};

export const orderOwners = createSlice({
  name: "orderOwner",
  initialState,
  reducers: {
    resetState(state) {
      state.orders = []
      state.selects = []
    },
    setState(state, action: PayloadAction<any>) {
      const { data, field }: any = action.payload;
      state[String(field)] = data;
    },
  },
});

const authReducer = orderOwners.reducer;
const { setState, resetState } = orderOwners.actions;
export { setState, resetState, authReducer };
